MainApp.controller("paymentController", ['$rootScope','$scope', 'HeaderVisibility', '$routeParams', '$localStorage', '$timeout', 'localStorageService', 'ipCookie', 'SharedData', '$location', function($rootScope,$scope, HeaderVisibility, $routeParams, $localStorage, $timeout, localStorageService, ipCookie,  SharedData, $location) {

    $scope.showLoginHeader = HeaderVisibility.getLoginHeader();
    $scope.showProfileHeader = HeaderVisibility.getProfileHeader();
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    $scope.loadingmessage = "Loading ...";
    $scope.setHeight = 300;
    if (SharedData.userData.emailId != '') {
        $scope.emailId = SharedData.userData.emailId;
        $scope.userName = SharedData.userData.userName;
        $scope.userId = SharedData.userData.userId;
    } else {
        $scope.emailId = ipCookie("userEmail");
        $scope.userName = ipCookie("userName");
        $scope.userId = ipCookie('userId');
    }
    $scope.$emit('userName', $scope.userName);
    $scope.$emit('emailId', $scope.emailId);
    let txnResponsParams = $location.search();
    let query = "query=cfreturn";
    Object.keys(txnResponsParams).forEach(function(paramObj){
        query = query + "&" + paramObj + "=" + txnResponsParams[paramObj];
    }); 
    let url = "yappes_home/"+ $scope.emailId+"?"+query;
    $location.url(url);

}]);