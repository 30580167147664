$(document).ready(function () {
	if ($(window).scrollTop() > 50) {
		$('header').addClass('js-stuck');
		$('body').addClass('js-stuck');
	} else {
		$('header').removeClass('js-stuck');
		$('body').removeClass('js-stuck');
	}

	$(window).scroll(function () {
		if ($(window).scrollTop() > 50) {
			$('header').addClass('js-stuck');
			$('body').addClass('js-stuck');
		} else {
			$('header').removeClass('js-stuck');
			$('body').removeClass('js-stuck');
		}
	});
	
	$(document).on("click", ".js-show-all", function() {
		$('.faq-content article:hidden').fadeIn(500);
		$('#showAllFaqbtn').fadeOut();
	});
	
	
	$(".nav-item").click(function () {
		// remove classes from all
		$(".nav-item").removeClass("active");
		// add class to the one we clicked
		$(this).addClass("active");
	});
	
	$(window).on('load', function(){
		$('.soft-load').fadeOut(600);
	});
	
	$(window).resize(function(){
		$('.soft-load').fadeOut(600);
	});
});
