MainApp.controller("ssoOauthCallbackController", ['$scope', 'generalServices', 'HeaderVisibility', 'AppConstants', 'ipCookie', 'SharedData', 'connectServices', '$window', '$location', 'localStorageService','$timeout', function($scope, generalServices, HeaderVisibility, AppConstants, ipCookie, SharedData, connectServices, $window, $location, localStorageService,$timeout) {
    $scope.showprogress = true;
    $scope.loadingclass = true;
    $scope.authenticationCookie = "";
    $scope.showLoginHeader = true;
    $scope.showProfileHeader = false;
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    $scope.loadingmessage = "Loading ...";
    $scope.setHeight = 300;
    $scope.tutorList = {};
    $scope.tutorList["PRE-0001"] = false;
    $scope.tutorList["PRE-0002"] = false;
    let userPreferenceList = [];
    $scope.loadingmessage = "Yappes - Github authentication in progress...";
    $scope.getParameterByName = function(name, url) {
        if (!url) {
            url = $location.absUrl();
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    var urllocation = $location.absUrl();
    if (localStorageService.get("") != null || !angular.isUndefined(localStorageService.get("ssoProfile"))) {
        $scope.authenticationCookie = JSON.parse(JSON.stringify(ipCookie('ssoProfile')));
    } else if (ipCookie('ssoProfile') != null) {
        $scope.authenticationCookie = JSON.parse(JSON.stringify(ipCookie('ssoProfile')));
    } else {
        $scope.authenticationCookie = "";
    }


    if ((urllocation.indexOf('code' + '=') != -1)) {
        $scope.field = 'code';
        $scope.code = $scope.getParameterByName($scope.field);
        $scope.TokenParams = {
            "code": $scope.code
        }
        connectServices.processSsoProfile($scope.TokenParams).then(function scb(resp) {
            let registrationSchema = {};
            if (angular.equals(resp.data.code, 200)) {
                registrationSchema.firstName = resp.data.username;
                registrationSchema.lastName = resp.data.username;
                registrationSchema.emailId = resp.data.emailId;
                registrationSchema.ssoDetails = {
                    "ssoProvider": "github"
                };
                if ($scope.authenticationCookie == "ssoRegister") {
                    generalServices.signUp(registrationSchema).then(function scb(resp) {
                        $scope.showprogress = false;
                        $scope.loadingclass = false;
                        $scope.code = resp.data.code;
                        if (ipCookie('ssoProfile') == 'ssoRegister') {
                            ipCookie.remove('ssoProfile', { path: '/' });
                        }
                        if (angular.equals(resp.data.code, 200)) {
                            $scope.msg = resp.data.data.message;
                            $scope.showModal = true;

                        } else if (angular.equals(resp.data.code, 400)) {
                            $scope.msg = resp.data.message;
                            $scope.showModal = true;
                        } else {
                            $scope.msg = resp.data.message;
                            $scope.showModal = true;
                        }
                    }, function ecb(err) {
                        $scope.showprogress = false;
                        $scope.loadingclass = false;
                        $scope.msg = "Something went wrong !!";
                        $scope.showModal = true;
                    });
                } else if ($scope.authenticationCookie == "ssoLogin") {
                    let loginSchema = {};
                    loginSchema.emailId = registrationSchema.emailId;
                    loginSchema.password = registrationSchema.password;
                    loginSchema.ssoProfile = "github";
                    generalServices.userSsoLogin(loginSchema).then(function scb(resp) {
                        $scope.emailId = "";
                        $scope.msg = "";
                        if (angular.equals(resp.data.code, 200)) {
                            $scope.emailId = resp.data.data.emailId;
                            $scope.msg = resp.data.message;
                            $scope.showLoginHeader = HeaderVisibility.getLoginHeader();
                            $scope.showProfileHeader = HeaderVisibility.getProfileHeader();
                            $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                            $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                            userPreferenceList = resp.data.data.userPreferenceList;

                            for (let tutorCount = 0; tutorCount < userPreferenceList.length; tutorCount++) {
                                $scope.tutorList[userPreferenceList[tutorCount].preferenceId] = userPreferenceList[tutorCount].status;
                            }
                            ipCookie('userId', resp.data.data.userId, { path: '/', expires: 1 });
                            ipCookie("userEmail", $scope.emailId, { path: '/', expires: 1 });
                            ipCookie("firstName", resp.data.data.firstName, { path: '/', expires: 1 });
                            ipCookie("lastName", resp.data.data.lastName, { path: '/', expires: 1 });
                            ipCookie("userName", resp.data.data.userName, { path: '/', expires: 1 });
                            ipCookie("needTutor", $scope.tutorList["PRE-0001"], { path: '/', expires: 1 });
                            ipCookie("needVideoSupport", $scope.tutorList["PRE-0002"], { path: '/', expires: 1 });

                            SharedData.updateUserData(
                                resp.data.data.userName,
                                resp.data.data.userId,
                                resp.data.data.emailId,
                                resp.data.data.firstName,
                                resp.data.data.lastName,
                                $scope.tutorList["PRE-0001"],
                                $scope.tutorList["PRE-0002"]

                            );
                            mixpanel.identify(resp.data.data.userId);
                            mixpanel.people.set({
                                "$first_name": resp.data.data.firstName,
                                "$last_name": resp.data.data.lastName,
                                "$created": "2016-09-01T09:02:00",
                                "$email": resp.data.data.emailId,
                                "$last_login": new Date()
                            });
                            mixpanel.track("Session-Start", {
                                "User": SharedData.userData.emailId,
                                "Start Time": new Date()
                            });

                            if (localStorageService.get("apishare") == null || angular.isUndefined(localStorageService.get("apishare"))) {
                                var url = "yappes_home/" + $scope.emailId;
                                $location.path(url);
                            } else {
                                var apishare = localStorageService.get("apishare");
                                localStorageService.remove("apishare");
                                $scope.backNavigation = {
                                    "previous": "homepage",
                                    "searchfiltered": "no",
                                    "filteredTag": ""
                                };
                                localStorageService.set("navigationState", $scope.backNavigation);
                                var url = apishare.referenceValue + "/" + $scope.emailId;
                                $location.path(url);

                            }

                        } else if (angular.equals(resp.data.code, 400)) {
                            $scope.msg = resp.data.message?resp.data.message:resp.data.data.message;
                            $scope.showLoginHeader = true;
                            $scope.showProfileHeader = false;
                            $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                            $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                            $scope.showModal = true;
                            $scope.showprogress = false;
                            $scope.loadingclass = false;
                        } else if (angular.equals(resp.data.code, 401)) {
                            $scope.msg = resp.data.message?resp.data.message:resp.data.data.message;
                            $scope.showLoginHeader = true;
                            $scope.showProfileHeader = false;
                            $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                            $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                            $scope.showModal = true;
                            $scope.showprogress = false;
                            $scope.loadingclass = false;
                        } else {
                            $scope.msg = "User does not Exists , Click on Signup with Github";
                            $scope.showLoginHeader = true;
                            $scope.showProfileHeader = false;
                            $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                            $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                            $scope.showModal = true;
                            $scope.showprogress = false;
                            $scope.loadingclass = false;
                        }

                    }, function ecb(err) {
                        $scope.showprogress = false;
                        $scope.loadingclass = false;
                        $scope.msg = "Login Failed";
                        $scope.showModal = true;
                    });
                }
            } else if (angular.equals(resp.data.code, 400)) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = resp.data.message;
                $scope.showModal = true;
            } else if (angular.equals(resp.data.code, 401)) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = resp.data.message;
                $scope.showModal = true;
            } else {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = "Something happened !!! Try again ...";
                $scope.showModal = true;
            }
        }, function ecb(err) {
            $scope.msg = err.data.message;
            $scope.showModal = true;
            $location.url($location.path());
            if ($scope.authenticationCookie == "ssoRegister") {
                var url = "signup";
            } else if ($scope.authenticationCookie == "ssoLogin") {
                var url = "login";
            } else {
                var url = "home";
            }
            $location.path(url);
        });
    } else if (urllocation.indexOf('error')) {
        $scope.field = 'error';
        $scope.error = $scope.getParameterByName($scope.field);
        $scope.msg = "Encountered an issue with github, please contact us";
        $scope.showModal = true;
        $timeout(function() {
            $scope.showprogress = false;
            $scope.loadingclass = false;
            $location.url($location.path());
            if ($scope.authenticationCookie == "ssoRegister") {
                var url = "signup";
            } else if ($scope.authenticationCookie == "ssoLogin") {
                var url = "login";
            } else {
                var url = "home";
            }
            $location.path(url);
        }, 3000);

    }
    //login 
    $scope.close = function() {
        ipCookie.remove('ssoProfile', { path: '/' });
        $location.url($location.path());
        var url = "login";
        $location.path(url);
    }
}]);