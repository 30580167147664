if (!angular.lowercase)
    angular.lowercase = function (str) { angular.isString(str) ? str.toLowerCase() : str; }
var MainApp = angular.module("MainApp", ['ngResource', 'ngRoute', 'ui.bootstrap', 'ngStorage', 'ui-notification', 'toggle-switch', 'ipCookie', 'angular-confirm', 'LocalStorageModule', 'ngFileUpload', 'textAngular', 'angular-intro', 'ngclipboard', 'angularMoment', 'countUpModule', 'angular-inview', 'countTo', '720kb.socialshare', '720kb.fx']);

MainApp.config(["$routeProvider", "$locationProvider", function ($routeProvider, $locationProvider) {
    $routeProvider.
        when('/login', {
            templateUrl: 'views/signin.html',
            contoller: 'SignInCtrl'
        }).
        when('/signup', {
            templateUrl: 'views/signup.html',
            contoller: 'SignUpCtrl'
        }).
        when('/yappes_home/:param', {
            templateUrl: 'views/yappes_home.html',
            contoller: 'YappesHomeCntrl'
        }).
        when('/account/:param', {
            templateUrl: 'views/user_profile.html',
            contoller: 'userProfileCntrl'
        }).
        when('/Logout', {
            templateUrl: 'views/home.html',
            controller: 'yappesDefaultCntrl'
        }).
        when('/activation/:param', {
            templateUrl: 'views/user_activation.html',
        }).
        when('/reset/:param', {
            templateUrl: 'views/reset_password.html',
        }).        
        when('/oauth2/callback/', {
            templateUrl: 'views/oauth_callback.html',
            contoller: 'apiOauthCallbackController'
        }).
        when('/oauth1/callback/', {
            templateUrl: 'views/oauth1_callback.html',
            contoller: 'apiOauth1CallbackController'
        }).
        when('/user/notify', {
            templateUrl: 'views/notify_payment.html'
        }).
        when('/sso/auth/cbresponse', {
            templateUrl: 'views/sso_oauth_callback.html',
            contoller: 'ssoOauthCallbackController'
        }).
        when('/organizationdetail/:param1', {
            templateUrl: 'views/organization_details.html',
            contoller: 'organizationViewController'
        }).
        when('/license/postpayment',{
        templateUrl: 'views/handle_payment.html'
        }).         
        otherwise({
            templateUrl: 'views/home.html'
            });
    $locationProvider.html5Mode({
        enabled: true
    });
}]);

MainApp.constant('AppConstants', {
    "api_endpoint": '../dispatcher/php_services.php?target=controlleradmin',
    "aws_api_endpoint": '../dispatcher/php_services.php?target=awsoperation',
    "aws_security_key_file_upload": '../dispatcher/php_services.php?target=awsoperationSecurityKey',
    "aws_security_key_file_delete": '../dispatcher/php_services.php?target=awsoperationDeleteSecurityKey',
    "aws_profilepic_api_endpoint": '../dispatcher/php_services.php?target=awsoperationforprofilepic',
    "aws_operationfororganization_api_endpoint": '../dispatcher/php_services.php?target=awsoperationfororganization',
    "api_curl_preview_endpoint": '../dispatcher/php_services.php?target=apipreview',
    "api_curl_multipart_endpoint": '../dispatcher/php_services.php?target=multipartData',
    "api_preview_oauth1_endpoint": '../dispatcher/php_services.php?target=previewOauth1Token',
    "api_preview_oauth1_access_token_endpoint": '../dispatcher/php_services.php?target=previewOauth1AccessToken',
    "api_json_upload_endpoint": '../dispatcher/php_services.php?target=uploadjsonfile',
    "api_sso_redirect": '../dispatcher/php_services.php?target=sso',
    "api_sso_process": '../dispatcher/php_services.php?target=ssoProcessProfile',
    "reset_password_mail": "We are sending the mail ...",
    "login_operation": "Welcome to Yappes... We are validating your credentials...",
    "update_profile": "We are updating your profile...",
    "change_password": "Regular password change improves your security...We are updating your profile...",
    "api_metadata_update": "Updation in progress...",
    "api_metadata_create": "Creation in progress...",
    "api_tags_update": "Updation in progress...",
    "yappes_signup": "Thanks for choosing Yappes...Sign-up in progress...",
    "search_api": "Search is in progress...",
    "api_logo_update": "Logo update in progress...",
    "security_key_file_upload": "Security Key file uploading...",
    "add_end_point": "Adding resource end point is in progress...",
    "add_end_point_header": "Adding header is in progress...",
    "add_end_point_query": "Adding query parameter is in progress...",
    "add_end_point_form": "Adding form data parameter is in progress...",
    "add_price_info": "Adding pricing information is in progress...",
    "yappes_home_url": "https://marketplace.yappes.com",
    "yappes_socket_url": "",
    "routemap_not_connect": "Not able to connect to Internet",
    "deal_room_details": "Getting the Deal Room details and the relevant chat messages...",
    "create_deal_room": "Creating the Private Deal room for the buyer and seller to discuss...",
    "videoSupportSets": {
        "home": "https://www.youtube.com/embed/xT0SwoOwQ5c",
        "createNewAPI": "https://www.youtube.com/embed/yCGtmVV6FIg",
        "onboardAPI": "https://www.youtube.com/embed/7AB64rQamk4",
    },
    "snippet_url": "https://github.com/yappes-technologies/logic-examples"
});
MainApp.controller("MainAppCntrl", ['$rootScope', '$scope', 'ipCookie', 'localStorageService', '$location', '$window', '$route', 'Resources', 'AppConstants', 'generalServices', 'SharedData', '$sce', function ($rootScope, $scope, ipCookie, localStorageService, $location, $window, $route, Resources, AppConstants, generalServices, SharedData, $sce) {
    this.$onInit = function () {
        $scope.protocol = $location.protocol();
        $scope.hostName = $location.host();
        AppConstants.yappes_home_url = $scope.protocol + "://" + $scope.hostName;
        if ($scope.hostName == "marketplace.yappes.local") {
            AppConstants.yappes_socket_url = $scope.protocol + "://" + "localhost:3001";
            AppConstants.yappes_docs_url = "https://docs.yappes.com/";
            AppConstants.yappes_blog_url = "https://medium.com/yappes";
        } else if ($scope.hostName == "sandbox.marketplace.yappes.com") {
            AppConstants.yappes_socket_url = $scope.protocol + "://" + "localhost:3001";
            AppConstants.yappes_docs_url = "https://docs.yappes.com/";
            AppConstants.yappes_blog_url = "https://medium.com/yappes";
        } else if ($scope.hostName == "marketplace.yappes.com") {
            AppConstants.yappes_socket_url = $scope.protocol + "://" + "api.yappes.com";
            AppConstants.yappes_docs_url = "https://docs.yappes.com/";
            AppConstants.yappes_blog_url = "https://medium.com/yappes";
        } else {
            AppConstants.yappes_socket_url = $scope.protocol + "://" + "localhost:3001";
            AppConstants.yappes_docs_url = "https://docs.yappes.com/";
            AppConstants.yappes_blog_url = "https://medium.com/yappes";
        }



        $rootScope.$on('showProfileHeader', function (event, data) {
            $scope.showProfileHeader = data;

            if ($scope.userId == undefined) {
                var myElement = angular.element(document.querySelector('#contentpart'));
                myElement.removeClass('makeSpaceOnLogin');
            } else {
                var myElement = angular.element(document.querySelector('#contentpart'));
                myElement.addClass('makeSpaceOnLogin');
            }


            if (angular.isDefined($scope.userId)) {

                if ($scope.showProfileHeader == true) {
                }

            }

        });
    }
    // Help
    $scope.showHelpContents = false;
    $scope.publicAPISearch = false;
    $scope.helpFlag = true;
    $scope.viewName = "";
    $scope.getShowHelpContents = {
        "viewName": "",
        "viewPath": "",
        "videoUrl": "",
        "videoTitle": "",
        "relevanceArticleSets": [
            {
                "name": "",
                "indexLable": "",
                "videoUrl": "",
                "videoTitle": "",
                "paragraph": "",
                "steps": [
                    "step1", "step2", "step3"
                ]
            }],
        "allArticleSets": [
            {
                "name": "",
                "indexLable": "",
                "videoUrl": "",
                "videoTitle": "",
                "paragraph": "",
                "steps": [
                    "step1", "step2", "step3"
                ]
            }]
    };
    $scope.showArtilceIndex = true;
    $scope.showArtilceContent = false;
    $scope.isSelectedContent = "nothing";

    $scope.setArticleIndex = function () {
        $scope.showArtilceIndex = true;
        $scope.isSelectedContent = "nothing";
    }

    $scope.showMyContent = function (indexId, contentId) {
        $scope.showArtilceIndex = false;
        $scope.isSelectedContent = contentId;
    }
    $scope.showMyIndex = function () {
        $scope.showArtilceIndex = true;
        $scope.isSelectedContent = "nothing";
    }

    //help
    $scope.viewAll = {
        "viewPath": "/all/views/info@yappes.com"
    };

    // Help    
    $rootScope.$on('$routeChangeStart', function (event, next, current) {
        $scope.protocol = $location.protocol();
        $scope.hostName = $location.host();
        AppConstants.yappes_home_url = $scope.protocol + "://" + $scope.hostName;
        if ($scope.hostName == "marketplace.yappes.local") {
            AppConstants.yappes_socket_url = $scope.protocol + "://" + "localhost:3001";
            AppConstants.yappes_docs_url = "https://docs.yappes.com/";
            AppConstants.yappes_blog_url = "https://medium.com/yappes";
        } else if ($scope.hostName == "sandbox.marketplace.yappes.com") {
            AppConstants.yappes_socket_url = $scope.protocol + "://" + "localhost:3001";
            AppConstants.yappes_docs_url = "https://docs.yappes.com/";
            AppConstants.yappes_blog_url = "https://medium.com/yappes";
        } else if ($scope.hostName == "marketplace.yappes.com") {
            AppConstants.yappes_socket_url = $scope.protocol + "://" + "api.yappes.com";
            AppConstants.yappes_docs_url = "https://docs.yappes.com/";
            AppConstants.yappes_blog_url = "https://medium.com/yappes";
        }
        if (angular.isDefined(ipCookie('userId')) && ipCookie('userId') != '') {
            if (SharedData.userData.emailId != '') {
                $scope.userId = SharedData.userData.userId;
                $scope.emailId = SharedData.userData.emailId;
                // Make sure fcWidget.init is included before setting these values
                // To set unique user id in your system when it is available
                //$window.fcWidget.setExternalId(SharedData.userData.userName);
                // To set user name
                //$window.fcWidget.user.setFirstName(SharedData.userData.firstName);
                // To set user email
                //$window.fcWidget.user.setEmail(SharedData.userData.userEmail);                
            } else {
                $scope.userId = ipCookie('userId');
                $scope.emailId = ipCookie('userEmail');
                // Make sure fcWidget.init is included before setting these values
                // To set unique user id in your system when it is available
                //$window.fcWidget.setExternalId(ipCookie('userName'));
                // To set user name
                //$window.fcWidget.user.setFirstName(ipCookie('firstName'));
                // To set user email
                //$window.fcWidget.user.setEmail(ipCookie('userEmail'));                
            }
        }

        var path = $location.path();
        // Help
        $scope.viewData = {
            "viewPath": path
        };


        if (angular.isDefined($location.search().reference)) {
            if ($location.search().reference == "share") {

                if (angular.isDefined(ipCookie('userId')) && ipCookie('userId') != '') {

                    var url = AppConstants.yappes_home_url + "/" + path + "/" + $scope.emailId;
                    $window.location.href = url;
                } else {
                    var shareUrl = {
                        "referenceName": "share",
                        "referenceValue": path
                    }
                    localStorageService.set("apishare", shareUrl);
                    var url = AppConstants.yappes_home_url + "/" + "login";
                    $window.location.href = url;

                }
            }
        }

        $scope.aboutlink = "#overviewli";
        $scope.pricelink = "#pricing";
        $scope.faqlink = "#faqs1";
        if (path == "/signup" || path == "/login") {
            $scope.aboutlink = "/home#overviewli";
            $scope.pricelink = "/home#pricing";
            $scope.faqlink = "/home#faqs1";
        } else {
            $scope.aboutlink = "#overviewli";
            $scope.pricelink = "#pricing";
            $scope.faqlink = "#faqs1";
        }

        let validViewPaths = [
            "/about", "/signup", "/home", "/login", "/activation", "/reset", "/", "/pricing",
            "/for-developers", "/for-api-providers", "/for-api-providers", "/terms-condition",
            "/privacy-policy", "/contactus", "/searchapi", "/publicapiview", "/sso/auth/cbresponse"
        ];
        let isShowContents = false;

        if (path.match(new RegExp(/\/activation\/[0-9a-zA-Z]+/))) {
            path = "/activation";
        }
        if (path.match(new RegExp(/\/reset\/[0-9a-zA-Z]+/))) {
            path = "/reset";
        }

        if (path == "/signup" || path == "/home" || path == "/login" || path == "/") {
            if (angular.isDefined(ipCookie('userId')) && ipCookie('userId') != '') {
                var url = AppConstants.yappes_home_url + "/yappes_home/" + $scope.emailId;
                $window.location.href = url;
            }
        }
        if (path != "/appDashboard") {
            clearInterval(SharedData.monitorIntervals.realTimeApiCheck);
            clearInterval(SharedData.monitorIntervals.liveApiCheck);
        }
        else if (angular.isUndefined(ipCookie('userId')) || ipCookie('userId') == '') {
            for (let pathCount = 0; pathCount < validViewPaths.length; pathCount++) {
                if (path == validViewPaths[pathCount]) {
                    isShowContents = true;
                }
            }
            if (!isShowContents) {
                var url = AppConstants.yappes_home_url + "/";
                $window.location.href = url;
            }
        }

        if (path == "/searchapi" || path == "/publicapiview") {
            $scope.publicAPISearch = true;
            ipCookie("touch_scroll_list", true);
        } else {
            if (ipCookie("touch_scroll_list")) {
                ipCookie.remove("touch_scroll_list")
            }
            $scope.publicAPISearch = false;
        }

        // Help
        generalServices.showHelpContentsService($scope.viewData).then(function scb(resp) {
            if (angular.isDefined(resp.data.data)) {
                $scope.getShowHelpContents = resp.data.data[0];
                if ($scope.getShowHelpContents.videoUrl.length > 0) {
                    let videoTempUrl = angular.copy($scope.getShowHelpContents.videoUrl);
                    let videoThumbnailId = videoTempUrl.substring(videoTempUrl.indexOf("/embed/") + "/embed/".length);
                    let tempVideo = "http://img.youtube.com/vi/your_youtube_id/default.jpg";
                    $scope.getShowHelpContents.videoThumbnail = tempVideo.replace("your_youtube_id", "videoThumbnailId");
                }
            }
        },
            function ecb(err) {
                $scope.msg = err.data.message;
                $scope.showModal = true;
            });
    });

    $scope.apiDetails = function (objectId, apiId, deliveryStatus) {
        var data = { "deliveryStatus": "read" };
        if (deliveryStatus == "unread") {
            generalServices.updateUserNotifications($scope.userId, objectId, data).then(function scb(resp) {
                if (angular.equals(resp.data.code, 200) && angular.isDefined(resp.data.data.message)) {
                    localStorageService.set("setTab", "apiDetails");
                    var url = "/consumer_view/" + apiId + "/" + $scope.emailId;
                    $location.path(url);
                }
                else {
                    $scope.msg = "Something went wrong !!";
                    $scope.showModal = true;
                }
            }, function ecb(err) {
                $scope.msg = err.data.message;
                $scope.showModal = true;
            });
        }
        else {
            localStorageService.set("setTab", "apiDetails");
            var url = "/consumer_view/" + apiId + "/" + $scope.emailId;
            $location.path(url);
        }
    }
    $rootScope.$on('showLoginHeader', function (event, data) {
        $scope.showLoginHeader = data;
    });


    $rootScope.$on('emailId', function (event, data) {
        $scope.emailId = data;

    });

    $rootScope.$on('userName', function (event, userName) {
        $scope.userName = userName;
    });

    $scope.scrollUp = true;
    $scope.show1 = true;
    $scope.show2 = false;
    $scope.show3 = false;
    $scope.show4 = false;
    $scope.show5 = false;
    $scope.showf1 = function () {
        $scope.show1 = true;
        $scope.show2 = false;
        $scope.show3 = false;
        $scope.show4 = false;
        $scope.show5 = false;

    }
    $scope.showf2 = function () {
        $scope.show2 = true;
        $scope.show1 = false;
        $scope.show3 = false;
        $scope.show4 = false;
        $scope.show5 = false;
    }
    $scope.showf3 = function () {
        $scope.show3 = true;
        $scope.show2 = false;
        $scope.show1 = false;
        $scope.show4 = false;
        $scope.show5 = false;
    }
    $scope.showf4 = function () {
        $scope.show4 = true;
        $scope.show2 = false;
        $scope.show3 = false;
        $scope.show1 = false;
        $scope.show5 = false;
    }
    $scope.showf5 = function () {
        $scope.show5 = true;
        $scope.show4 = false;
        $scope.show2 = false;
        $scope.show3 = false;
        $scope.show1 = false;
    }
    $scope.showFeedback = false;
    $scope.data = { "ticketType": "", "comments": "" }

    $scope.cType = [{ "name": "suggestions" }, { "name": "issues" }, { "name": "other" }];
    $scope.data.ticketType = $scope.cType[0].name;

    $scope.sendFeedback = function (emailId) {
        $scope.showFeedback = true;

        $scope.buttonClicked = "Please provide your feedback."
    }
    $scope.showprogressfeedback = false;
    $scope.loadingclassfeedback = false;
    $scope.showFeedModal = false;
    $scope.submitFeedback = function () {
        $scope.showprogressfeedback = true;
        $scope.loadingclassfeedback = true;
        setTimeout(function () {
            Resources.submitFeedbackData($scope.data).then(function scb(resp) {
                $scope.showprogressfeedback = false;
                $scope.loadingclassfeedback = false;
                if (angular.equals(resp.data.code, 200)) {
                    $scope.showFeedModal = true;
                    $scope.msg = resp.data.data.message;
                    $scope.data.comments = "";
                } else {
                    $scope.msg = "Something went wrong !!";
                    $scope.showFeedModal = true;
                }
            }, function ecb(err) {

                $scope.msg = "Something went wrong !!";
                $scope.showFeedModal = true;
                $scope.showprogressfeedback = false;
                $scope.loadingclassfeedback = false;
            });
        }, 2000);
    }
    $scope.discoverDifferentApis = function (emailId) {
        localStorageService.clearAll();
        var url = "discoverApi/" + emailId;
        $location.path(url);
    }
    $scope.goToAppDashboard = function (emailId) {
        localStorageService.clearAll();
        $("#dashboardTab>ul>li.active").removeClass("active");
        $("#myapis").addClass("active");
        $("#myapisView").addClass("active");

        $scope.$broadcast('refreshDashboardView', emailId);
        var url = "/appDashboard/" + emailId;
        $location.path(url);
    }
    $scope.goToDocs = function () {
        var url = AppConstants.yappes_docs_url;
        $window.open(url, '_blank');
    }
    $scope.goToBlog = function () {
        var url = AppConstants.yappes_blog_url;
        $window.open(url, '_blank');
    }
    $scope.txnAnimate = new CountUp("txncount", 0, 1000, 0, 2);
    $scope.inViewTxnNumbers = function (index, inview, inviewpart) {
        $scope.txnAnimate.reset();
        $scope.txnAnimate.start();
    }

    // HELP
    $scope.showSupportArticle = function () {
        $scope.showHelpContents = true;
    }

    $scope.closeSupportArticle = function () {
        $scope.showHelpContents = false;
    }

    $scope.logout = function () {
        $scope.showprogressfeedback = true;
        $scope.loadingclassfeedback = true;
        generalServices.userLogout().then(function scb(resp) {
            $scope.showprogressfeedback = false;
            $scope.loadingclassfeedback = false;
            ipCookie.remove('userId');
            ipCookie.remove('userEmail');
            ipCookie.remove('needTutor');
            ipCookie.remove('previewDetails');
            ipCookie.remove('redirection');
            ipCookie.remove('redirection_consumer');
            ipCookie.remove('CollectionId');
            ipCookie.remove('currentApiProviderUserName');
            ipCookie.remove('previousApiProviderUserName');
            ipCookie.remove('firstName');
            ipCookie.remove('lastName');
            ipCookie.remove('userName');
            ipCookie.remove('ssoProfile');
            localStorageService.clearAll();
            $window.sessionStorage["redirection_consumer"] = null;
            $window.sessionStorage["redirection"] = null;
            var url = AppConstants.yappes_home_url;
            window.location.href = url;
        }, function ecb(err) {
            $scope.showprogress = false;
            $scope.loadingclass = false;
            $scope.showprogressfeedback = false;
            $scope.loadingclassfeedback = false;
            $scope.msg = "Login Failed";
            $scope.showModal = true;
        });
    }

    function noBack() {
        window.history.forward()
    }

    noBack();
    window.onload = noBack;
    window.onpageshow = function (evt) {
        if (evt.persisted) noBack()
    }
    window.onunload = function () { void (0) }
}]);
MainApp.service('HeaderVisibility', function () {
    var val1 = true;
    var val2 = false;
    this.getProfileHeader = function () {
        return val1;
    }
    this.getLoginHeader = function () {
        return val2;
    }
});
MainApp.service('setEmailId', function () {
    var email = "";
    this.setEmail = function (eId) {
        email = eId;
    }
    this.getEmail = function () {
        return email;
    }
});
MainApp.directive('pwCheck', [function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            var firstPassword = '#' + attrs.pwCheck;
            elem.add(firstPassword).on('keyup', function () {
                scope.$apply(function () {
                    var v = elem.val() === $(firstPassword).val();
                    ctrl.$setValidity('pwmatch', v);
                });
            });
        }
    }
}]);
MainApp.filter('parseNumber', function () {
    return function (num) {
        return parseInt(num);
    }
});
MainApp.filter('notNull', function () {
    return function (state) {
        if (state == null) {
            return false
        } else {
            return true;
        }
    }
});

MainApp.filter('capitalFirst', [function () {
    return function (string) {
        let tagList = string.split(',');
        for (let tagIndex = 0; tagIndex < tagList.length; tagIndex++) {
            tagList[tagIndex] = tagList[tagIndex].charAt(0).toUpperCase() + tagList[tagIndex].slice(1);
        }
        let tags = tagList.join(',');
        return tags;
    };
}])
MainApp.filter('first50', [function () {
    return function (string) {

        if (string.length > 50) {
            return string.slice(0, 50) + "...";
        } else {
            return string;
        }
    };
}])
MainApp.filter('first90', [function () {
    return function (string) {
        if (string && string.length > 90) {
            return string.slice(0, 90) + "...";
        } else {
            return string;
        }
    };
}])
MainApp.filter('first15', [function () {
    return function (string) {

        if (string.length > 15) {
            return string.slice(0, 15);
        } else {
            return string;
        }
    };
}])
MainApp.filter('removeSpaces', [function () {
    return function (string) {
        if (!angular.isString(string)) {
            return string;
        }
        return string.replace(/[\s]/g, '');
    };
}])
MainApp.filter('dateSuffix', ["$filter", function ($filter) {
    let suffixes = ["th", "st", "nd", "rd"];
    return function (input) {
        let dtfilter = $filter('date')(input, 'MMMM dd');
        let day = parseInt(dtfilter.slice(-2));
        let relevantDigits = (day < 30) ? day % 20 : day % 30;
        let suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
        return dtfilter + suffix;
    };
}]);

MainApp.directive(
    'modale',
    function () {
        return {
            template: '<div class="modal fade" >' + '<div class="modal-dialog modal-md">' + '<div class="modal-content" style="margin-top: 12%;">' + '<div class="modal-header">' + '<button type="button" ng-click="closePopUp()" class="close" id="close-modal" data-dismiss="modal" aria-hidden="true" style=" color:white;">&times</button>' + '<h4 class="modal-title" style="text-align:center; color:white;"> Change Password</h4>' + '</div>'

                + ' <div class="form-group "> <div class="col-md-4"><label for="ChangePassword" class="control-label">Current Password</label></div>' + '<div class="col-md-6"><input type="password" class="form-control" id="currentPassword" name="currentPassword" placeholder="Current Password" ng-model="changePassword.currentPassword" ng-required="true">' + '</div>' + '<button  style="margin-left:35%;" type="button" ng-click="onSubmit();" class="btn btn-raised btn-primary" data-dismiss="modal">Submit</button></div>' + '</div>' + '</div>' + '</div>',
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: true,
            link: function postLink(scope, element, attrs) {

                scope.$watch(attrs.visible, function (value) {

                    if (value == true) {
                        $(element).modal('show');
                    } else {
                        $(element).modal('hide');
                    }

                });
                $(element).on('shown.bs.modal', function () {
                    scope.$apply(function () {
                        scope.$parent[attrs.visible] = true;
                    });
                });

                $(element).on('hidden.bs.modal', function () {
                    scope.$apply(function () {
                        scope.$parent[attrs.visible] = false;
                    });
                });
            }
        };
    });
MainApp.directive('autoActive', ['$location', function ($location) {
    return {
        restrict: 'A',
        scope: false,
        link: function (scope, element) {
            function setActive() {
                var path = $location.path();
                if (path) {
                    angular.forEach(element.find('li'), function (li) {
                        var anchor = li.querySelector('a');
                        if (anchor.href.match('#' + path + '(?=\\?|$)')) {
                            angular.element(li).addClass('active');
                        } else {
                            angular.element(li).removeClass('active');
                        }
                    });
                }
            }
            setActive();
            scope.$on('$locationChangeSuccess', setActive);
        }
    }
}]);
MainApp.factory('SharedData', function () {
    return {
        userData: {
            userName: '',
            userId: '',
            emailId: '',
            firstName: '',
            lastName: '',
            needTutor: '',
            needVideoSupport: ''
        },
        apiProviderData: {
            currentUserName: '',
            previousUserName: ''
        },
        monitorIntervals: {
            realTimeApiCheck: '',
            liveApiCheck: ''
        },
        updateUserData: function (userName, userId, emailId, firstName, lastName, needTutor, needVideoSupport, externalTeamDetails) {
            this.userData.userName = userName;
            this.userData.userId = userId;
            this.userData.emailId = emailId;
            this.userData.firstName = firstName;
            this.userData.lastName = lastName;
        },
        updateUserPreferencesData: function (needTutor, needVideoSupport) {
            this.userData.needTutor = needTutor;
            this.userData.needVideoSupport = needVideoSupport;
        },
        updateApiProviderData: function (userName) {
            this.apiProviderData.currentUserName = userName;
        },
        updatePreviousApiProviderData: function (userName) {
            this.apiProviderData.previousUserName = userName;
        }
    };
});

MainApp.factory('socket', ['$rootScope', 'AppConstants', function ($rootScope, AppConstants) {
    var socket = io(AppConstants.yappes_socket_url + '/deals', {
        transports: ['websocket']
    });
    return {
        on: function (eventName, callback) {
            socket.on(eventName, callback);
        },
        emit: function (eventName, data) {
            socket.emit(eventName, data);
        }
    };
}]);

MainApp.directive('scrollOnClick', function () {
    $("#arrowUp").hide();
    return {
        link: function (scope, $elm, attrs) {
            var idToScroll = attrs.href;
            $elm.on('click', function () {
                var $target;
                if (idToScroll) {
                    $target = $(idToScroll);
                } else {
                    $target = $elm;
                }
                $("html , body").animate({ scrollTop: $target.offset().top - 20 }, 1000);
                return false;
            });
        }
    }
});

MainApp.directive("scroll", ["$window", function ($window) {
    return function (scope, element, attrs) {
        $("#arrowUp").hide();
        angular.element($window).bind("scroll", function () {
            if (this.pageYOffset >= 300) {
                $("#arrowUp").show();
            } else {
                $("#arrowUp").hide();
            }
            scope.$apply();
        });
    };
}]);

MainApp.directive('variableByConvention', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                var pattern = new RegExp(/(^[a-z]+)([A-Za-z0-9]*)/, 'g');

                // If value is empty or undefined
                if (inputValue == undefined)
                    return ''

                // Should not start with an UpperCase or a Digit
                if (inputValue.length == 1) {
                    // If initial character is an alphabet
                    if (/^[a-zA-Z]/.test(inputValue)) {
                        modelCtrl.$setViewValue(inputValue.toLowerCase());
                        modelCtrl.$render();
                        return inputValue.toLowerCase();
                    }
                    // If initial character is a digit
                    else if (/^[\d]/.test(inputValue)) {
                        modelCtrl.$setViewValue("");
                        modelCtrl.$render();
                        return "";
                    }
                }

                /*Should not start with a space 
                (Is only triggered once a character is entered after a white space)*/
                if (/\s/g.test(inputValue)) {
                    let wsIndex = inputValue.lastIndexOf(" ");

                    // If a character if present after the white space
                    if (/[a-zA-Z0-9]/g.test(inputValue[wsIndex + 1])) {
                        let charAfterWS = inputValue[wsIndex + 1].toUpperCase();
                        let result = inputValue.slice(0, inputValue.indexOf(" ")) + charAfterWS;
                        result = result.replace(/[^a-zA-Z0-9]/g, '')
                        modelCtrl.$setViewValue(result);
                        modelCtrl.$render();
                        return result;
                    }
                }

                // Replace all special characters
                var transformedInput = inputValue.replace(/[^a-zA-Z0-9]/g, '');
                if (transformedInput != inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }

                return transformedInput;
            });
        }/*End of link()*/
    };
});

MainApp.directive('modal', function () {
    return {
        template: '<div class="modal fade"  data-backdrop=\x22static\x22 data-keyboard=false  >' + '<div class="modal-dialog" style="width:{{setWidth}}">' + '<div class="modal-content" style="margin-top: 12%;">' + '<div class="modal-header">' + '<button type="button" ng-click="closePopUp()" class="close" id="close-modal" data-dismiss="modal" aria-hidden="true" style=" color:white;">&times</button>' + '<h4 class="modal-title" style="text-align:center; color:white;"> {{ buttonClicked  }}</h4>' + '</div>' + '<div class="modal-body" id="modalHeight" ng-transclude style="height:{{setHeight}}px;"></div>' + '</div>' + '</div>' + '</div>',
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: true,
        link: function postLink(scope, element, attrs) {
            scope.$watch(attrs.visible, function (value) {

                if (value == true)
                    $(element).modal('show');
                else
                    $(element).modal('hide');
            });

            $(element).on('shown.bs.modal', function () {
                scope.$apply(function () {
                    scope.$parent[attrs.visible] = true;
                });
            });

            $(element).on('hidden.bs.modal', function () {
                scope.$apply(function () {
                    scope.$parent[attrs.visible] = false;
                });
            });
        }
    };
});

MainApp.directive('numbersOnly', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                if (inputValue == undefined)
                    return ''
                var transformedInput = inputValue.replace(/[^0-9]/g, '');
                if (transformedInput != inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }

                return transformedInput;
            });
        }
    };
});

MainApp.directive('alphsOnly', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                if (inputValue == undefined)
                    return ''
                var transformedInput = inputValue.replace(/[^a-zA-Z]/g, '');
                if (transformedInput != inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }

                return transformedInput;
            });
        }
    };
});

MainApp.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which == 13 || event.key == "Enter") {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter, { 'event': event });
                });
                event.preventDefault();
            }
        });
    };
});

MainApp.directive('ssoSubmitForm', ['$timeout', '$sce', 'AppConstants', function ($timeout, $sce, AppConstants) {
    return {
        replace: true,
        scope: {},
        template: '<form action="{{ ssoUrl }}" method="POST">' +
            '</form>',
        link: function ($scope, element, $attrs) {
            $scope.$on('sso-redirect', function (event, data) {
                $scope.ssoUrl = "/dispatcher/php_services.php?target=sso";
                $timeout(function () {
                    element.submit();
                });
            });
        }
    }
}]);
MainApp.directive('customOnChange', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            let onChangeHandler = scope.$eval(attrs.customOnChange);
            element.on('change', onChangeHandler);
            element.on('$destroy', function () {
                element.off();
            });

        }
    };
});
