MainApp.factory('apiCollections', ['AppConstants', '$q', '$http', function(AppConstants, $q, $http) {

    return {
        createDeal: function(dataToServer) {
            var url = "/connect/deals";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllDealsAsProvider: function() {
            var url = "/connect/provider/deals";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllConsumerDeals: function() {
            var url = "/connect/consumer/deals";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getDealDetailsAsProvider: function(dealId) {
            var url = "/connect/user/deals/" + dealId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getDealInfo: function(dealId) {
            var url = "/connect/user/deals/" + dealId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getSubscriptioninfo: function(userId) {
            var url = "/connect/consumers/subscribed/" + userId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getDealsChatInfo: function(dealId) {
            var url = "/connect/deals/chats/" + dealId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        createDealChat: function(dealId, dataToServer) {
            var url = "/connect/deals/chats/" + dealId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updateDealStatus: function(dealId, dataToServer) {
            var url = "/connect/deals/" + dealId
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getExistingApplicationInfo: function(userId) {
            var url = "/content/application/";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        createNewApplication: function(dataToServer) {
            var url = "/content/application/";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updateApplication: function(applicationId, dataToServer) {
            var url = "/content/application/" + applicationId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApplicationById: function(applicationId) {
            var url = "/content/application/" + applicationId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        deleteApplication: function(applicationId) {
            var url = "/content/application/" + applicationId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApplicationKeys: function(applicationId) {
            var url = "/content/application/keys/" + applicationId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        regenerateApplicationKeys: function(applicationId, dataToServer) {
            var url = "/content/application/keys/" + applicationId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApplicationApis: function(applicationId) {
            var url = "/connect/app/apilink/" + applicationId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        addToApplication: function(dataToServer) {
            var url = "/connect/app/apilink";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        deleteAPIFromApplication: function(relationId) {
            var url = "/connect/app/apilink/" + relationId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        PostReview: function(apiId, dataToServer) {
            var url = "/content/review/rating/" + apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getReviewofAnAPIByUser: function(apiId) {
            var url = "/content/review/rating/user/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        UpdateReview: function(reviewId, dataToServer) {
            var url = "/content/review/rating/user/" + reviewId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getReviewofAnAPI: function(apiId) {
            var url = "/content/review/rating/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getReviewofProviderAPI: function(userId) {
            var url = "/content/review/rating/apis/" + userId
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getAverageRatingsAPI: function(apiId) {
            var url = "/content/review/ratings/average/" + apiId
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
      //Get Colletions 
        getCollections: function(userId) {
            var url = "/content/collections";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        //Insert Colletions 
        PostCollections: function(dataToServer) {
            var url = "/content/collections";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
      //Update Colletions 
        updateCollections: function(collectionId,dataToServer) {
            var url = "/content/collections/"+collectionId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        // Get Collection Objects
        getCollectionObjects : function(collectionId){
           
            var url = "/content/collections/"+ collectionId +"/objects";

            var op = "get";

            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                    .then(function scb(successResponse){
                        
                        return successResponse;
                    },function ecb(errorResponse){
                          
                        return errorResponse;
                    });
      },
       //Insert Colletion Object
        insertCollectionObject: function(collectionId,dataToServer) {
            var url = "/content/collections/"+ collectionId +"/objects";
            var op = "post";
            var data = dataToServer;
            
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
      //Update Colletion Object
        updateCollectionObject: function(collectionId,objectId,dataToServer) {
            var url = "/content/collections/"+collectionId+"/objects/"+objectId;
            var op = "put";
            var data = dataToServer;          
           
           
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        //Get Subscribed API and User list 
        getSubscribedAPIList: function(providerId) {
            var url = "/connect/providers/"+ providerId +"/apilists";

            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        }                  
    }
}])
