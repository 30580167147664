MainApp.factory('generalServices', ['AppConstants', '$q', '$http', function(AppConstants, $q, $http) {

    return {
        signUp: function(data) {
            var url = "/general/register";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'without',signupFlag:'getClientIp' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        userLogin: function(data) {
            var url = "/general/authenticate";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'login' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        userSsoLogin: function(data) {
            var url = "/general/sso/authenticate";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'ssoLogin' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getUserProfile: function(emailid) {
            var url = "/content/profile/" + emailid;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updateUserProfile: function(emailId, dataToServer) {
            var url = "/content/profile/" + emailId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateUserPassword: function(emailId, dataToServer) {
            var url = "/content/profile/password/" + emailId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        createNewAPI: function(dataToServer) {
            var url = "/content/definitions";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        listExistingApis: function(emailId,searchParam,organizationId) {
            var url = "/content/definitions/provider/"+emailId+"?searchname="+searchParam+"&organizationId="+organizationId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        listAnalyticsApis: function(apiId,metricLevel,jsonArray) {
            var url = "/content/apis/"+apiId+"/analytics?metric="+metricLevel;
            var op = "post";
            var data = jsonArray;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        listAnalyticsApisConsumers: function(applicationId,metricLevel,jsonArray) {
            var url = "/content/applications/"+applicationId+"/analytics?metric="+metricLevel;
            var op = "post";
            var data = jsonArray;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getApiDetails: function(apiId) {
            var url = "/content/definitions/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateAPI: function(apiId, dataToServer) {
            var url = "/content/definitions/" + apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getTagDetails: function() {
            var url = "/content/tags";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        sendMailForPasswordReset: function(data) {
            var url = "/general/reset/password";
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'without' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        resetPwdLinkValidity: function(data) {
            var url = "/general/token/validity";
            var op = "post";
            var data = data;

            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'without' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateApiTags: function(apiId, dataToServer) {
            var url = "/content/tags/" + apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        resetPassword: function(data, token) {
            var url = "/content/reset/password/" + token;
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'without' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });


        },
        getAllExistingTags: function(apiId) {
            var url = "/content/tags/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getApiSubTags: function(data) {

            var url = "/content/user/subtags/" + data.tagId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        searchApiByName: function(data) {
            var url = "/content/discover/freetext"
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getAllApis: function(data) {
            var url = "/content/discover/api"
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        uploadToAws: function(data, file) {

            var data = data;
            var fd = new FormData();
            fd.append('file', file);
            return $http.post(AppConstants.aws_api_endpoint, fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': '' }
                })
                .success(function scb(resp) {
                    return resp;
                })
                .error(function ecb(err) {
                    return err;
                });

        },
        updateLogoUrl: function(data, apiId) {
            var url = "/content/definitions/logo/" + apiId;
            var op = "put";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updateProfilePicLogoUrl: function(data, userId) {

            var url = "/content/user/profile/image/" + userId;
            var op = "put";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        DeleteProfilePic: function(data, userId) {

            var url = "/content/user/profile/image/" + userId;
            var op = "delete";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        userActivation: function(dataToServer) {
            var url = "/general/token/validity";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'without' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        resendActivation: function(dataToServer) {

            var url = "/general/activation/mail";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'without' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getPlatformKey: function(apiId) {
            var url = "/content/api/platformkey/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        regeneratePlatformKey: function(dataToServer) {
            var url = "/content/api/platformkey/";
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getUserPublicProfile: function(userName) {
            var url = "/content/profile/provider/" + userName;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getUserNotifications: function(userId) {
            var url = "/content/users/"+userId+"/events";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateUserNotifications: function(userId,objectId,data) {
            var url = "/content/users/"+userId+"/events/"+objectId;
            var op = "put";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        userLogout: function() {
            //var url="";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ operation: op, accessToken: 'logout' }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        checkActivePricingPlanAPILimit: function(userId) {
            var url = "/content/yappes/plans/check/"+userId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getUserPricingPlanDetails:function(userId){
            var url = "/content/yappes/plans/ypsubscriptions/"+userId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updatePricingPlanDetails:function(data){
            var url = "/content/yappes/plans/subscriptions";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getUserBillingDetails:function(userId){
            var url = "/content/user/billing/"+userId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        updateUserBillingDetails:function(userId,data){
            var url = "/content/user/billing/"+userId;
            var op = "put";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        getUserInvoiceDetails:function(userId){
            var url = "/content/user/"+userId+"/invoices";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        getUserPrefrences:function(data){
            var url = "/content/user/"+data.userId+"/preferences";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        updatesUserPreferences:function(data){
            var url = "/content/user/"+data.userId+"/preferences";
            var op = "put";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        showHelpContentsService:function(data){
            var url = "/content/help/relevance";
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                            .then(function scb(resp){
                                return resp;
                            }, function ecb(err){
                                return err;
                            });
        },
        getApiConfig:function(data){
            var url = "/content/api/"+data.apiId+"/environmentconfig";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        updateApiConfig:function(data){
            var url = "/content/api/"+data.apiId+"/environmentconfig/"+data.objectId;
            var op = "put";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        fetchPublicApiList:function(data){
            var url = "/content/discover/searchapis";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data, accessToken: 'public'}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        getPublicTagDetails:function(data){
            var url = "/content/public/tags";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data, accessToken: 'public'}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        getPublicAPIDetails:function(data){
            var url = "/content/public/apiview";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data, accessToken: 'public'}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        getPublicAPIDetailsByName:function(data){
            var url = "/content/discover/searchapis";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data, accessToken: 'public'}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        ssoGithubSignUp:function(){
            var url = "/sso/auth";
            var op = "get";
            var data ={};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data, accessToken: 'sso'}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        ssoGithubSignUpCallback:function(data){
            var url = "sso/auth?cbresponse";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data, accessToken: 'public'}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        createOrganization: function(dataToServer) {
            var url = "/content/user/organization";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updateOrganization: function(organizationId,dataToServer) {
            var url = "/content/user/organization/"+ organizationId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getAllOrganization: function(){
            var url = "/content/user/organizationlist";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getAllOrganizationWriteAccess: function(){
            var url = "/content/user/organizationlist";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getASingleOrganization: function(organizationId){
            var url = "/content/user/organization/details/"+ organizationId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        deleteAOrganization: function(organizationId){
            var url = "/content/user/organization/owner/"+ organizationId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        changeOwnershipOrganization: function(dataToServer,organizationId){
            var url = "/content/user/organization/ownertransfer/"+ organizationId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data }))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        createTeam: function(dataToServer){
            var url = "/content/organization/teams";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op, data: data }))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        updateTeam: function(dataToServer,teamId){
            var url = "/content/organization/teams/"+ teamId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data }))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getTeamInformation: function(teamId){
            var url = "/content/organization/teams/"+ teamId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getAllTeams: function(organizationId){
            var url = "/content/organization/teamlist/"+ organizationId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        deleteTeamInformation: function(teamId){
            var url = "/content/organization/teams/"+ teamId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        queryUser: function() {
            var url = "/general/application/userlist";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp.data;
                },function ecb(err){
                    return err;
                });
        },
        queryOrganizationUser: function(organizationId) {
            var url = "/content/organization/userlist/"+ organizationId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp.data;
                },function ecb(err){
                    return err;
                });
        },
        addUserToOrganization: function(dataToServer) {
            var url = "/content/organization/adduser";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getAddedUserInOrganization: function(organizationId) {
            var url = "/content/organization/userlist/" + organizationId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        removeUserFromOrganization: function(dataToServer) {
            var url = "/content/organization/removeuser";
            var op = "delete";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        addUserToTeam: function(dataToServer) {
            var url = "/content/organization/teams/adduser";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        removeUserFromTeam: function(dataToServer) {
            var url = "/content/organization/teams/removeuser";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        listUserInTeam: function(dataToServer) {
            var url = "/content/organization/teams/userlist";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        createANewRole: function(dataToServer) {
            var url = "/content/organization/role";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        deleteARole: function(roleId) {
            var url = "/content/organization/role/" + roleId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        updateARole: function(roleId,dataToServer) {
            var url = "/content/organization/role/" + roleId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getAllRoles: function(organizationId) {
            var url = "/content/organization/allrole/"+organizationId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        createUserRoleMap: function(dataToServer) { //Admin Enterprise
            var url = "/content/organization/user/role";
            var op = "get";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        updateUserRoleMap: function(dataToServer) {
            var url = "/content/organization/user/role";
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getUserRoleMap: function(organizationId) {
            var url = "/content/organization/user/role/"+organizationId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        getAllOrganizationUserOwnerMember: function(userType) {
            var url = "/content/user/organization/" + userType;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        deleteApi: function(apiId) {
            var url = "/content/user/api/"+apiId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        changeApiOwnerWithoutAssociation: function(dataToServer) {
            var url = "/content/user/owner/apitransfer/"+ dataToServer.apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        changeApiOwnerWithAssociation: function(dataToServer) {
            var url = "/content/user/organization/apitransfer/"+ dataToServer.apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url : url,operation : op,data: data}))
                .then(function scb(resp){
                    return resp;
                },function ecb(err){
                    return err;
                });
        },
        storeMultipartFile: function(data) {
            var url = "";
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_curl_multipart_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        createApiTracker: function(data) {
            var url = "/content/monitor/api";
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApiTrackers: function(data) {
            var url = "/content/monitor/api";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        createEndpointTracker: function(dataToServer,apiId) {
            var url = "/content/monitor/"+ apiId +"/endpoints"
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updateEndPointTracker: function(data,apiId,resourceId) {
            var url = "/content/monitor/"+ apiId +"/endpoints/"+ resourceId;
            var op = "put";
            delete data.resources.resourceId;
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        deleteEndPointTracker: function(apiId,resourceId) {
            var url = "/content/monitor/"+ apiId +"/endpoints/"+ resourceId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        purchaseLicense: function(data) {
            var url = "/content/product/license/purchase";
            var op = "post";
            var data = data;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op,data: data}))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getPurchasedLicense: function(apiId,resourceId) {
            var url = "/content/product/license/purchase";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getProductList: function() {
            var url = "/content/product/list";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getSinglePurchasedLicense: function(id) {
            var url = "/content/product/license/purchase/"+id;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getPurcahseList: function() {
            var url = "/content/product/license/purchase";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },

        initPaymentService:function(data){
            var url = "/content/product/license/initpayment";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op, data: data}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
        getPurcahseOrderConfigs:function(data){
            var url = "/content/purchaseorder/configs";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({url: url, operation: op}))
                .then(function scb(resp){
                    return resp;
                }, function ecb(err){
                    return err;
                });
        },
    }
}]);
