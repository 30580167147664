MainApp.controller("organizationViewController", ['$scope', 'generalServices', 'HeaderVisibility', 'AppConstants', 'ipCookie', 'SharedData', '$window', '$location', 'localStorageService', '$timeout', 'Upload', '$confirm', function($scope, generalServices, HeaderVisibility, AppConstants, ipCookie, SharedData, $window, $location, localStorageService, $timeout, Upload, $confirm) {
    $scope.showprogress = true;
    $scope.loadingclass = true;
    $scope.loadingmessage = "Loading ...";
    $timeout(function() {
        $scope.showprogress = false;
        $scope.loadingclass = false;
    }, 2000);
    $scope.showLoginHeader = HeaderVisibility.getLoginHeader();
    $scope.showProfileHeader = HeaderVisibility.getProfileHeader();
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    $scope.loadingmessage = "Loading ...";
    $scope.setHeight = 400;
    if (SharedData.userData.emailId != '') {
        $scope.emailId = SharedData.userData.emailId;
        $scope.userName = SharedData.userData.userName;
        $scope.userId = SharedData.userData.userId;
        $scope.getNeedTutor = SharedData.userData.needTutor;
        $scope.getVideoSupport = SharedData.userData.needVideoSupport;
    } else {
        $scope.emailId = ipCookie("userEmail");
        $scope.userName = ipCookie("userName");
        $scope.userId = ipCookie('userId');
        $scope.getNeedTutor = ipCookie('needTutor');
        $scope.getVideoSupport = ipCookie('needVideoSupport');
    }
    $scope.$emit('userName', $scope.userName);
    $scope.$emit('emailId', $scope.emailId);
    localStorageService.remove("createApiId");
    localStorageService.remove("collectionId");
    localStorageService.remove("collectionName");
    $scope.organizationViewDetails = {
        organizationId: "",
        organizationName: "",
        description: "",
        logoUrl: ""
    };
    $scope.globalOrganizationLogoUrl = "";
    $scope.orglogoimg = "../dist/images/api-defaultlogo.png";

    if (localStorageService.get("isOrganizationView")) {
        $scope.organizationViewDetails = localStorageService.get("organizationViewDetails");
        generalServices.getASingleOrganization($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            $scope.data = resp.data.data;
            $scope.organizationName = resp.data.data.organizationName;
            $scope.organizationDescription = resp.data.data.description;
            $scope.orglogoimg = resp.data.data.logoUrl + '?decache=' + Math.random();
            $scope.globalOrganizationLogoUrl = resp.data.data.logoUrl;
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });

    }
    $scope.backFromOrganization = function() {
        localStorageService.set("setTab", "organizations");
        history.pushState(null, 'any', "appDashboard/" + $scope.emailId);
    }

    $scope.updateOrganizationModal = function() {
        $scope.updateOrganizationModalFlag = true;
        $scope.buttonClicked = "Update Organization";
    }

    $scope.updateOrganization = function(organizationObj) {
        $scope.organizationUpdateObj = {
            organizationName: "",
            description: "",
        };
        $scope.organizationUpdateObj.organizationName = organizationObj.organizationName;
        $scope.organizationUpdateObj.description = organizationObj.description;
        $scope.organizationUpdateObj.logoUrl = $scope.globalOrganizationLogoUrl;
        generalServices.updateOrganization($scope.organizationViewDetails.organizationId, $scope.organizationUpdateObj).then(function scb(resp) {
            $scope.msg = resp.data.data.message;
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.updateOrganizationModalFlag = true;
            }
            if (resp.data.code == 200) {
                generalServices.getASingleOrganization($scope.organizationViewDetails.organizationId).then(function scb(resp) {
                    $scope.data = resp.data.data;
                    $scope.organizationName = resp.data.data.organizationName;
                    $scope.organizationDescription = resp.data.data.description;
                    $scope.showModal = true;
                    $scope.updateOrganizationModalFlag = false;
                }, function ecb(err) {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;

                });
            }
        }, function ecb(err) {
            $scope.msg = resp.data.data.message;
            $scope.showModal = true;
        });
    }

    $scope.apiDetailsTab = function() {
        generalServices.getASingleOrganization($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            $scope.data = resp.data.data;
            $scope.organizationName = resp.data.data.organizationName;
            $scope.organizationDescription = resp.data.data.description;
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.peopleTab = function() {
        $scope.getOrganizationUsers();
    }

    $scope.getListTeamViewFlag = true;

    $scope.teamsTab = function() {
        generalServices.getAllTeams($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            $scope.teamObj = resp.data.data;
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.createTeamModal = function() {
        $scope.addANewTeam = true;
        $scope.buttonClicked = "Add Team";
    }

    $scope.createTeamObj = {
        "teamName": "",
        "description": ""
    };

    $scope.createTeam = function() {
        $scope.createTeamObj.organizationId = $scope.organizationViewDetails.organizationId;
        generalServices.createTeam($scope.createTeamObj).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.createTeamModal();
            } else if (resp.data.code == 200) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.addANewTeam = false;
                $scope.teamsTab();
            }
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });

    }

    $scope.updateTeamModal = function(team) {
        $scope.updateTeamModalFlag = true;
        $scope.teamUpdateObj = team;
        $scope.buttonClicked = "Update Team";
    }

    $scope.updateTeamObj = {
        "teamName": "",
        "description": ""
    };

    $scope.updateTeam = function(teamObj, organizationId) {
        $scope.updateTeamObj.teamName = teamObj.teamName;
        $scope.updateTeamObj.description = teamObj.description;
        generalServices.updateTeam($scope.updateTeamObj, teamObj.teamId).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.teamsTab();
            } else if (resp.data.code == 200) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.updateTeamModalFlag = false;
                $scope.teamsTab();
            }

        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.inviteUserModal = function() {
        $scope.inviteUserModalFlag = true;
        $scope.buttonClicked = "Invite User";
        $scope.usersListSearchQueryList = [];
    }

    $scope.userInviteArray = [];

    $scope.orgTeam = {
        selected: ""
    }

    $scope.onDropDownSelectedUser = function($item, $model, $label, $event) {
        $scope.selected = $model;
        for (let i = 0; i < $scope.usersListSearchQueryList.length; i++) {
            if ($scope.usersListSearchQueryList[i]['emailId'] == $model) {
                alreadyInOrNotIndex = $scope.userInviteArray.indexOf($model); 
                if (alreadyInOrNotIndex == -1) {
                    $scope.userInviteArray.push($model);
                    $scope.orgTeam.selected = ' ';
                    alreadyInOrNotIndex = -1;
                } else {
                    $scope.msg = "User already added, Please select a different user!!";
                    $scope.showModal = true;
                }
            }
        }
    }

    $scope.textChangedOrganizationUser = function() {
        generalServices.queryOrganizationUser($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            $scope.usersListSearchQueryList = resp.data;
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.textChanged = function() {
        generalServices.queryUser().then(function scb(resp) {
            $scope.usersListSearchQueryList = resp.data;
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.onClickAddUserButton = function(userObjToAddOrganization) {
        var userObjToAddOrganization = {
            organizationId: $scope.organizationViewDetails.organizationId,
            userIdentifierList: $scope.userInviteArray
        }
        generalServices.addUserToOrganization(userObjToAddOrganization).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.getOrganizationUsers();
                $scope.getAllUserRoleMap($scope.organizationViewDetails.organizationId);
            } else if (resp.data.code == 200) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.inviteUserModalFlag = false;
                $scope.userInviteArray = [];
                $scope.getOrganizationUsers();
                $scope.getAllUserRoleMap($scope.organizationViewDetails.organizationId);
            }
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });

    }

    $scope.onDeselectUser = function(indexOfUser) {
        $scope.userInviteArray.splice(indexOfUser, 1);
    }

    $scope.getOrganizationUsers = function() {
        generalServices.getAddedUserInOrganization($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
            } else if (resp.data.code == 200) {
                $scope.userListToAdd = resp.data.data;
            }
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.removeUserObjFromOrganization = { "userIdentifier": "", "organizationId": "" }
    $scope.removeUserFromOrganization = function(userEmailId) {
        $scope.removeUserObjFromOrganization.userIdentifier = userEmailId;
        $scope.removeUserObjFromOrganization.organizationId = $scope.organizationViewDetails.organizationId;
        $confirm({ text: "Are you want to remove User from Organization ?", title: 'Remove the user', ok: 'Yes', cancel: 'No' }).then(function() {
            generalServices.removeUserFromOrganization($scope.removeUserObjFromOrganization).then(function scb(resp) {
                if (resp.data.code == 400) {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                    $scope.getOrganizationUsers();

                } else if (resp.data.code == 200) {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                    $scope.getOrganizationUsers();

                }
            }, function ecb(err) {
                $scope.msg = "Something went wrong !!";
                $scope.showModal = true;
            });
        },function(){
            // Handling No
        });        

    }

    $scope.teamObjFromOrganizationViewController = '';

    $scope.teamNameClick = function (teamObj){
        localStorageService.set("setTab", "teams");
        localStorageService.set("teamObj",teamObj);
        $scope.teamObjFromOrganizationViewController = teamObj; 
        localStorageService.set("teamsView", true);        
        var url = "/organizationdetail/team/" + $scope.emailId;
        $location.path(url);
    }

    $scope.addANewRolesModal = function() {
        $scope.addNewRolesModalViewFlag = true;
        $scope.buttonClicked = "Add a New Role";
    }

    $scope.accessSpecifiersObj = [
        { "accessSpecifier": { "readAccess": "true", "writeAccess": "false" }, "lable": "READ" },
        { "accessSpecifier": { "readAccess": "true", "writeAccess": "true" }, "lable": "READ/WRITE" }
    ]

    $scope.updateRoleObjRoleId = "";

    $scope.updateRoleObj = {
        "roleName": "",
        "organizationId": "",
        "accessSpecifiers": ""
    }

    $scope.updateRoleObj.organizationId = $scope.organizationViewDetails.organizationId;

    $scope.updateRolesModal = function(role) {
        $scope.updateRolesModalViewFlag = true;
        $scope.buttonClicked = "Update A Role";
        $scope.updateRoleObjRoleId = role.roleId;
        $scope.updateRoleObj.roleName = role.roleName;
        $scope.updateRoleObj.accessSpecifiers = JSON.stringify(role.accessSpecifiers);
    }

    $scope.onClickUpdateRolesButton = function(roleId, roleData) {
        roleData.accessSpecifiers = JSON.parse(roleData.accessSpecifiers); //Parse the String and Make as JSON
        generalServices.updateARole(roleId, roleData).then(function scb(resp) {
            $scope.msg = resp.data.data.message;
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.updateRolesModalViewFlag = true;
                $scope.updateRoleObj.accessSpecifiers = JSON.stringify($scope.updateRoleObj.accessSpecifiers);
            }else if (resp.data.code == 200) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.updateRolesModalViewFlag = false;
                $scope.getAllRoles();
            }
        }, function ecb(err) {
            $scope.msg = resp.data.data.message;
            $scope.showModal = true;
        });
    }

    $scope.selectedAccessSpecifier = {
        "selectedPermission": ""
    }

    $scope.createRolesObj = { "roleName": "", "organizationId": "", "accessSpecifiers": { "readAccess": true, "writeAccess": "" } }

    $scope.createRolesObj.organizationId = $scope.organizationViewDetails.organizationId;

    $scope.selectedAccessSpecifier.selectedPermission = JSON.stringify($scope.accessSpecifiersObj[0].accessSpecifier);

    $scope.onClickAddANewRolesButton = function() {
        $scope.selectedAccessSpecifier.selectedPermission = JSON.parse($scope.selectedAccessSpecifier.selectedPermission); //Radio Button Value Only supports String
        $scope.createRolesObj.accessSpecifiers = $scope.selectedAccessSpecifier.selectedPermission;
        generalServices.createANewRole($scope.createRolesObj).then(function scb(resp) {
            $scope.msg = resp.data.data.message;
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.addNewRolesModalViewFlag = true;
                $scope.selectedAccessSpecifier.selectedPermission = JSON.stringify($scope.selectedAccessSpecifier.selectedPermission);
            }else if (resp.data.code == 200) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
                $scope.addNewRolesModalViewFlag = false;
                $scope.createRolesObj.roleName = '';
                $scope.selectedAccessSpecifier.selectedPermission = JSON.stringify($scope.accessSpecifiersObj[0].accessSpecifier)
                $scope.getAllRoles();
            }
        }, function ecb(err) {
            $scope.msg = resp.data.data.message;
            $scope.showModal = true;
        });
    }

    $scope.getAllRoles = function() {
        generalServices.getAllRoles($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
            } else if (resp.data.code == 200) {
                $scope.allRolesList = resp.data.data;
                $scope.allRoleListModal = resp.data.data;
                $scope.allRoleListModal.shift(); //Two option dropdown
            }
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.rolesTab = function(){
        generalServices.getAllRoles($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
            } else if (resp.data.code == 200) {
                $scope.allRolesListRolesTab = resp.data.data;
            }
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });   
    }


    $scope.removeARoleButton = function(role) {
        $confirm({ text: "Are you want to remove role ?", title: 'Remove the role', ok: 'Yes', cancel: 'No' }).then(function() {
            generalServices.deleteARole(role.roleId).then(function scb(resp) {
                if (resp.data.code == 400) {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                    $scope.getAllRoles();
                } else if (resp.data.code == 200) {
                    $scope.allRolesList = resp.data.data;
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                    $scope.getAllRoles();
                }
            }, function ecb(err) {
                $scope.msg = "Something went wrong !!";
                $scope.showModal = true;
            });
        }, function() {
            // Handling No
        });
    }

    $scope.selectedRoleForUser = {
        "rolename" : ""
    }    

    $scope.onClickOpenRoleMapUpdateModal = function(user, roleDetail) {
        $scope.updateRolesModalOrganizationTabViewFlag = true;
        $scope.buttonClicked = "Edit User Details";
        $scope.userEmailId = user.emailId;
        $scope.selectedRoleForUser.roleName = roleDetail.roleDetails[0].roleName
    }

    $scope.updateUserRoleMap = { "userIdentifier": "", "organizationId": "", "roleMaps": "" };

    let roleIds = [];

    $scope.onClickRoleMapUpdateForUser = function(userEmailId) {
        $scope.updateUserRoleMap.userIdentifier = userEmailId;
        $scope.updateUserRoleMap.organizationId = $scope.organizationViewDetails.organizationId;
        for (let i = 0; i < $scope.allRolesList.length; i++) {
            if ($scope.allRolesList[i].roleName == $scope.selectedRoleForUser.roleName) {
                roleIds.push({ "roleId": $scope.allRolesList[i].roleId })
            }
        }
        $scope.updateUserRoleMap.roleMaps = roleIds;
        generalServices.updateUserRoleMap($scope.updateUserRoleMap).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
            } else if (resp.data.code == 200) {
                $scope.selectedRoleForUser.selected = " ";
                roleIds = [];
                $scope.updateRolesModalOrganizationTabViewFlag = false;
                $scope.getAllUserRoleMap();
            }
        }, function ecb(err) {
            $scope.msg = resp.data.data.message;
            $scope.showModal = true;
        });
    }

    $scope.getAllUserRoleMap = function() {
        generalServices.getUserRoleMap($scope.organizationViewDetails.organizationId).then(function scb(resp) {
            if (resp.data.code == 400) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
            } else if (resp.data.code == 200) {
                $scope.allUserRoleMapList = resp.data.data;
            }
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }

    $scope.deleteOrganization = function() {
        $confirm({ text: "Are you sure want to delete this API?", title: 'Deleting API', ok: 'Yes', cancel: 'No' }).then(function() {
            generalServices.deleteAOrganization($scope.organizationViewDetails.organizationId).then(function scb(resp) {
                if (resp.data.code == 200) {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                    setTimeout(function() {
                        $scope.redirectMyApis();
                    },2000)
                } else {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                }
            }, function ecb(err) {
                $scope.msg = "Something went wrong !!";
                $scope.showModal = true;
            })
        }, function no() {
            //Handling NO
        });
    }


    $scope.userOwnerShipArrayOrganization = [];

    $scope.userOwnerShipOrganization = {
        selected: ""
    }


    $scope.onDropDownSelectedUserOrganization = function($item, $model, $label, $event) {
        $scope.selected = $model;
        for (let i = 0; i < $scope.usersListSearchQueryList.length; i++) {
            if ($scope.usersListSearchQueryList[i]['emailId'] == $model) {
                if ($scope.userOwnerShipArrayOrganization.length < 1 ){
                    $scope.userOwnerShipArrayOrganization.push($model);
                    $scope.userOwnerShipOrganization.selected = ' ';
                } else {
                    $scope.msg = "You have to select only one user To change Ownership";
                    $scope.showModal = true;
                }
            }
        }
    }

    $scope.onDeselectUserFromOwnerShip = function(index){
        $scope.userOwnerShipArrayOrganization.splice(index, 1);
    }


    $scope.changeOwnerShipModalFlag = false;

    $scope.redirectMyApis = function(){
        localStorageService.set("setTab", "myapis");
        $window.location.href = AppConstants.yappes_home_url +"/appDashboard/" + $scope.emailId;
    }
   

    $scope.changeOwnerShipModal = function() {
        $scope.buttonClicked = "Change Owner Ship of Organization";
        $scope.changeOwnerShipModalFlag = true;
        $scope.usersListSearchQueryList = [];
    }


    $scope.ownerShipDetails = {
        "userIdentifier":""
    }

    $scope.onClickButtonChangeOwnerShipOrganization = function(){
        $scope.changeOwnerShipModalFlag = false;
            $scope.ownerShipDetails.userIdentifier = $scope.userOwnerShipArrayOrganization[0];
            $confirm({ text: "Are you sure want to Change the Ownership of the Organization?", title: 'change Ownership of Organization', ok: 'Yes', cancel: 'No' }).then(function() {
                generalServices.changeOwnershipOrganization($scope.ownerShipDetails,$scope.organizationViewDetails.organizationId).then(function scb(resp) {
                    if (resp.data.code == 200) {
                        $scope.msg = resp.data.data.message;
                        $scope.showModal = true;
                        $scope.userOwnerShipArrayOrganization = [];
                        setTimeout(function() {
                            $scope.redirectMyApis();
                        }, 2000)
                    } else {
                        $scope.msg = resp.data.data.message;
                        $scope.showModal = true;
                        $scope.userOwnerShipArrayOrganization = [];
                    }
                }, function ecb(err) {
                    $scope.msg = "Something went wrong !!";
                    $scope.showModal = true;
                });
            }, function no() {
                // Handling NO
            });
    }

    let organizationLogoUpdate = {
        organizationName: "",
        description: "",
        logoUrl: ""
    }
    $scope.orglogoimg = "../dist/images/api-defaultlogo.png";
    $scope.uploadPic = function(file, organizationObj) {
        organizationLogoUpdate.organizationName = $scope.organizationViewDetails.organizationName;
        organizationLogoUpdate.description = $scope.organizationViewDetails.description;

        if (file == undefined) {
            $scope.msg = "Select a image to upload";
            $scope.showModal = true;
            $scope.disableFlag = true;
            return false;
        }
        if (file['size'] > 2000000) {
            $scope.msg = "Maximum 2MB is allowed";
            $scope.showModal = true;
            return false;
        }

        if (file == null) {
            $scope.msg = "Select a image to upload";
            $scope.showModal = true;
            return false;
        }

        if (file != null) {
            $scope.showprogress = true;
            $scope.loadingclass = true;
            $scope.loadingmessage = AppConstants.api_logo_update;
            Upload.upload({
                url: AppConstants.aws_operationfororganization_api_endpoint,
                data: {
                    file: file,
                    userId: $scope.userId,
                    organizationName: $scope.organizationViewDetails.organizationName,
                    organizationId: $scope.organizationViewDetails.organizationId,
                    filename: $scope.filename,
                }
            }).then(
                function(resp, data) {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    if (resp.status == 200) {
                        if (resp.data != 'Failed') {
                            $scope.logoUrl = resp['data'];
                            $scope.orglogoimg = $scope.logoUrl + '?decache=' + Math.random();
                            $scope.logoData = {
                                "logoUrl": $scope.logoUrl
                            };
                            angular.element("input[type='file']").val(null); // To reset the Image input
                            organizationLogoUpdate.logoUrl = $scope.logoData.logoUrl;
                            globalOrganizationLogoUrl = organizationLogoUpdate.logoUrl;
                            $scope.disableFlag = true; // Disable Update button
                            $scope.uploadButton = true; // Disable the Upload button
                            generalServices.updateOrganization($scope.organizationViewDetails.organizationId, organizationLogoUpdate).then(function scb(resp) {
                                $scope.showprogress = false;
                                $scope.loadingclass = false;
                                if (angular.equals(resp.data.code, 200)) {
                                    $scope.msg = resp.data.data.message;
                                    $scope.showModal = true;
                                    $scope.updateOrganizationModalFlag = false;
                                    $scope.organizationUpdateObj = organizationLogoUpdate.logoUrl;
                                    generalServices.getASingleOrganization($scope.organizationViewDetails.organizationId).then(function scb(resp) {
                                        $scope.data = resp.data.data;
                                        $scope.organizationName = resp.data.data.organizationName;
                                        $scope.organizationDescription = resp.data.data.description;
                                        $scope.orglogoimg = resp.data.data.logoUrl + '?decache=' + Math.random();
                                        $scope.globalOrganizationLogoUrl = resp.data.data.logoUrl;
                                    }, function ecb(err) {
                                        $scope.msg = "Something went wrong !!";
                                        $scope.showModal = true;
                                    });
                                } else if (angular.equals(resp.data.code, 401)) {
                                    $scope.msg = resp.data.message;
                                    $scope.showModal = true;
                                } else {
                                    $scope.msg = "Failed to Upload Logo";
                                    $scope.showModal = true;
                                }
                            }, function ecb(err) {
                                $scope.showprogress = false;
                                $scope.loadingclass = false;
                                $scope.msg = err.data.message;
                                $scope.showModal = true;
                            });
                        } else {
                            $scope.msg = "Failed to Upload";
                            $scope.showModal = true;
                        }
                    } else {
                        $scope.msg = "Failed to Upload";
                        $scope.showModal = true;
                    }
                },
                function(resp) {
                    $scope.msg = "Something went wrong";
                    $scope.showModal = true;
                },
                function(evt) {
                    $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                });
        }
    }

    $scope.disableFlag = true;
    $scope.uploadButton = true;
    $scope.disableButton = function(input) {
        if (input == 'img') {
            $scope.uploadButton = false;
        }
        if (input == 'text') {
            $scope.disableFlag = false;
        }
    }

    $scope.getAllRoles();
    $scope.getOrganizationUsers();
    $scope.getAllUserRoleMap();
}]);