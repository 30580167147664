MainApp.controller("forgotPwdCtrl", ['$scope', '$routeParams', 'generalServices', 'AppConstants', function($scope, $routeParams, generalServices, AppConstants) {
    $scope.setHeight = 300;
    $scope.showLoginHeader = true;
    $scope.showProfileHeader = false;
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    $scope.token = $routeParams.param;
    $scope.passwordplaceholder = false;
    $scope.tokencheckplaceholder = false;
    $scope.resetsuccessplaceholder = false;
    $scope.reset_password_change = AppConstants.reset_password_change;
    $scope.message = "";
    $scope.forgotPwdkeys = {
        "password": "",
        "confirmPassword": ""
    };
    $scope.pwdLinkValidity = function(token) {
        $scope.data = {
            "type": "reset-password",
            "token": token
        }
        generalServices.resetPwdLinkValidity($scope.data).then(function scb(resp) {
            if (angular.equals(resp.data.code, 200) && (resp.data.data.message = "valid-token")) {
                $scope.passwordplaceholder = true;
                $scope.tokencheckplaceholder = false;
                $scope.resetsuccessplaceholder = false;
                $scope.emailToResetPwd = resp.data.data.emailId;
            } else {
                $scope.passwordplaceholder = false;
                $scope.tokencheckplaceholder = true;
                $scope.resetsuccessplaceholder = false;
            }
        }, function ecb(err) {

            $scope.passwordplaceholder = false;
            $scope.tokencheckplaceholder = true;
            $scope.resetsuccessplaceholder = false;
        });
    }
    $scope.resetNewPwd = function() {
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.forgotPwdkeys.emailId = $scope.emailToResetPwd;
        generalServices.resetPassword($scope.forgotPwdkeys, $scope.token).then(function scb(resp) {
            $scope.loadingclass = false;
            $scope.showprogress = false;
            if (angular.equals(resp.data.code, 200) && (resp.data.data.message = "Password Updated Successfully.")) {
                $scope.ifSuccess = true;
                $scope.resetsuccessplaceholder = true;
                $scope.passwordplaceholder = false;
                $scope.tokencheckplaceholder = false;

            } else if (angular.equals(resp.data.code, 401)) {
                $scope.message = resp.data.message;
                $scope.ifSuccess = false;
                $scope.resetsuccessplaceholder = true;
                $scope.passwordplaceholder = false;
                $scope.tokencheckplaceholder = false;

            } else {
                $scope.message = "Something went wrong !Try Again ..."
                $scope.ifSuccess = false;
                $scope.resetsuccessplaceholder = true;
                $scope.passwordplaceholder = false;
                $scope.tokencheckplaceholder = false;
            }
        }, function ecb(err) {

            $scope.message = "Something went wrong !Try Again ..."
            $scope.ifSuccess = false;
        });
    }
    $scope.pwdLinkValidity($scope.token);
}]);
