MainApp.controller("userProfileCntrl", ['$rootScope','$scope', 'HeaderVisibility', '$routeParams', 'generalServices', '$localStorage', '$timeout', 'Notification', 'AppConstants', 'localStorageService', 'ipCookie', 'Upload', 'SharedData','$location','$document','$window','$confirm', function($rootScope,$scope, HeaderVisibility, $routeParams, generalServices, $localStorage, $timeout, Notification, AppConstants, localStorageService, ipCookie, Upload, SharedData, $location, $document, $window, $confirm) {
    $scope.showprogress = true;
    $scope.loadingclass = true;
    $scope.loadingmessage = "Loading ...";
    $timeout(function() {
        $scope.showprogress = false;
        $scope.loadingclass = false;
    }, 2000);
    $scope.showLoginHeader = HeaderVisibility.getLoginHeader();
    $scope.showProfileHeader = HeaderVisibility.getProfileHeader();
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    $scope.loadingmessage = "Loading ...";
    $scope.setHeight = 300;
    if (SharedData.userData.emailId != '') {
        $scope.emailId = SharedData.userData.emailId;
        $scope.userName = SharedData.userData.userName;
        $scope.userId = SharedData.userData.userId;
        $scope.getNeedTutor = SharedData.userData.needTutor;
        $scope.getVideoSupport = SharedData.userData.needVideoSupport;        
    } else {
        $scope.emailId = ipCookie("userEmail");
        $scope.userName = ipCookie("userName");
        $scope.userId = ipCookie('userId');
        $scope.getNeedTutor = ipCookie('needTutor');
        $scope.getVideoSupport = ipCookie('needVideoSupport');
    }
    $scope.$emit('userName', $scope.userName);
    $scope.$emit('emailId', $scope.emailId);
    localStorageService.remove("createApiId");
    localStorageService.remove("collectionId");
    localStorageService.remove("collectionName");
    $scope.userTab = "pi";
    $scope.starterFeatures = ['API Management','Distribution','Deal Room'];
    $scope.proFeatures = ['API Management','Distribution','Deal Room','Detailed Analytics','Collaboration'];

    $scope.isTabActive = function(tabName){
        return tabName===$scope.currentTab;
    }
    
    generalServices.getUserProfile($scope.emailId).then(function scb(resp) {

        if (angular.equals(resp.data.code, 200)) {
            $scope.YappesUserProfile = {
                "firstName": resp.data.data.firstName,
                "lastName": resp.data.data.lastName,
                "userName": resp.data.data.userName,
                "profileImageUrl": resp.data.data.profileImageUrl,
                "organizationName": resp.data.data.organizationName
            };
            $scope.userId = ipCookie('userId');

            $scope.picUrl = resp.data.data.profileImageUrl;
            $scope.$storage = $localStorage.$default({ userEmail: resp.data.data.emailId });
        }
    }, function ecb(err) {
        $scope.msg = "Some thing went wrong !!";
        $scope.showModal = true;
    });
    $scope.showModal = false;
    $scope.UpdateUserProfile = function() {
        $scope.loadingmessage = AppConstants.update_profile;
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.msg = "";
        delete $scope.YappesUserProfile["profileImageUrl"];
        setTimeout(function() {
            generalServices.updateUserProfile($scope.emailId, $scope.YappesUserProfile).then(function scb(resp) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                if (angular.equals(resp.data.code, 200)) {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                    SharedData.updateUserData(
                        $scope.YappesUserProfile.userName,
                        $scope.userId,
                        $scope.emailId,
                        $scope.YappesUserProfile.firstName,
                        $scope.YappesUserProfile.lastName,
                        $scope.getNeedTutor,
                        $scope.getVideoSupport
                    );
                    ipCookie("needTutor", $scope.getNeedTutor, { path: '/', expires: 1 });
                    ipCookie("needVideoSupport", $scope.getVideoSupport, { path: '/', expires: 1 });
                } else if (angular.equals(resp.data.code, 400)) {
                    $scope.msg = resp.data.message;
                    $scope.showModal = true;

                } else if (angular.equals(resp.data.code, 401)) {
                    $scope.msg = resp.data.message;
                    $scope.showModal = true;

                } else {
                    $scope.msg = "Something went wrong !!";
                    $scope.showModal = true;
                }
            }, function ecb(err) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = "Something went wrong !!";
                $scope.showModal = true;
            });
        }, 2000);
    }

    $scope.showPassword = false;
    $scope.changePassword = {
        "currentPassword": "",
        "newPassword": ""
    };
    $scope.changePassword = function() {
        $scope.showPassword = true;
    }
    $scope.notification = {};
    $scope.onSubmit = function() {
        $scope.loadingmessage = AppConstants.change_password;
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.changePasswordSets = {
            "currentPassword": $scope.changePassword.currentPassword,
            "newPassword": $scope.changePassword.newPassword
        }
        setTimeout(function() {
            generalServices.updateUserPassword($scope.emailId, $scope.changePasswordSets).then(function scb(resp) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                if (angular.equals(resp.data.code, 200)) {
                    $scope.msg = resp.data.data.message;
                    Notification.success($scope.msg);
                    $scope.changePasswordSets = {
                        "currentPassword": "",
                        "newPassword": ""
                    }

                } else if (angular.equals(resp.data.code, 400)) {
                    $scope.msg = resp.data.message;
                    Notification.error($scope.msg);


                } else {
                    $scope.msg = "Server Error";
                    Notification.error($scope.msg);
                }
            }, function ecb(err) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = "Server Error !!";
                Notification.error($scope.msg);
            });
        }, 2000);
    }
    $scope.picUrl = "https:\/\/yappes.s3.amazonaws.com\/profile\/default_profile.png";
    $scope.profileOpt = [{ "name": "Options" }, { "name": "Update Picture" }, { "name": "Remove Picture" }];
    $scope.selected = $scope.profileOpt[0].name;
    $scope.preview = false;
    $scope.onFileSelected = function($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {

        if ($file != null) {
            $scope.selected = $scope.profileOpt[0].name;
            //$scope.picUrl=$file['name'];
            if (!angular.isUndefined($file['name']))
                $scope.preview = true;
            else {
                $scope.msg = "File not selected";
                $scope.showModal = true;
            }
        }
    }
    $scope.save = function(file) {

        $scope.preview = false;
        $scope.picUrl = "https:\/\/yappes.s3.amazonaws.com\/profile\/default_profile.png";
        $scope.filename = $scope.userId + "_profile.png";
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.loadingmessage = "Updating Profile Picture...";
        Upload.upload({
            url: AppConstants.aws_profilepic_api_endpoint,
            data: {
                file: file,
                userId: $scope.userId,
                filename: $scope.filename,
            }
        }).then(
            function(resp, data) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.submit = true;
                if (resp.status == 200) {
                    if (resp.data != 'Failed') {
                        $scope.logoUrl = resp['data'];
                        $scope.apilogoimg = $scope.logoUrl + '?decache=' + Math.random();
                        $scope.logoData = {
                            "profileImageUrl": $scope.logoUrl
                        };
                        generalServices.updateProfilePicLogoUrl($scope.logoData, $scope.username).then(function scb(resp) {
                            if (angular.equals(resp.data.code, 200)) {
                                $scope.showprogress = false;
                                $scope.loadingclass = false;
                                $scope.picUrl = $scope.logoUrl;
                                $scope.msg = resp.data.data.message;
                                $scope.showModal = true;
                            } else if (angular.equals(resp.data.code, 401)) {
                                $scope.msg = resp.data.message;
                                $scope.showModal = true;
                            } else {
                                $scope.errorMsg = "Updating Failed";
                                $scope.msg = "Failed to Update ";
                                $scope.showModal = true;
                            }
                        }, function ecb(err) {
                            $scope.showprogress = false;
                            $scope.loadingclass = false;
                            $scope.msg = err.data.message;
                            $scope.showModal = true;
                        });
                    } else {
                        $scope.msg = "Failed to Update";
                        $scope.showModal = true;
                    }
                } else {
                    $scope.msg = "Failed to Update";
                    $scope.showModal = true;
                }
            },
            function(resp) {
                $scope.msg = "Something went wrong";
                $scope.showModal = true;
            },
            function(evt) {
                $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
    }
    $scope.showPic = true;
    $scope.show = false;
    $scope.showPopover = function() {
        $scope.show = true;
    };

    $scope.hidePopover = function() {
        $scope.show = false;
    };

    $scope.removePic = function() {

        $scope.data = { "profileImageUrl": $scope.picUrl }
        generalServices.DeleteProfilePic($scope.profileImageUrl, $scope.username).then(function scb(resp) {

            if (angular.equals(resp.data.code, 200)) {
                delete $scope.picUrl;
                $scope.picUrl = resp.data.data.profileImageUrl;
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
            } else if (angular.equals(resp.data.code, 401)) {
                $scope.msg = resp.data.message;
                $scope.showModal = true;
            } else {
                $scope.errorMsg = "Updating Failed";
                $scope.msg = "Failed to Update ";
                $scope.showModal = true;
            }
        }, function ecb(err) {
            $scope.showprogress = false;
            $scope.loadingclass = false;
            $scope.msg = "Some thing went wrong !!";
            $scope.showModal = true;
        });
    }
    $scope.trigg = function(s) {
        if (angular.equals(s, "Update Picture")) {
            angular.element('#upload').trigger('click');
        } else {
            $scope.removePic();
        }
    }

    // Get all the available pricing plan details and the logged in users current plan details
    $scope.getUserPricingPlanDetails = function(){
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.userPricingPlan = {};
        $scope.pricingPlansDetails = [];
        generalServices.getUserPricingPlanDetails($scope.userId).then(function scb(resp){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(resp.status,200))
            {
                if (resp.data.data.message != 'No data found.') {
                    $scope.userPricingPlan = resp.data.data.myPlan
                    $scope.pricingPlansDetails = resp.data.data.otherPlans;                    
                }
            } else if(angular.equals(resp.data.code,401)){
                $scope.msg=resp.data.message;
                $scope.showModal=true;
            }
            else {
                $scope.msg="Something went wrong ... Try again !!!";
                $scope.showModal=true;
            }
        
        },function ecb(err){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
          });        

    }

    $scope.updatePricingPlanDetails = function(action,planId){
        let confirmText = "Do you really want to "+action+" from your Current plan? If changed"+
                            " it will affect the number of 'New API Creation' and Transactions volume.";
        $confirm({ text: confirmText,
                title: 'Subscription Plan Change', ok: 'Yes', cancel: 'No' })
        .then(function(){
                $scope.updatePricingPlanAPI(planId);
            },function(){
            });        
    }

    $scope.updatePricingPlanAPI = function(planId){
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.userPricingPlan = {};
        $scope.pricingPlansDetails = [];
        let updateData = {
            "userId":$scope.userId,
            "planId":planId
        }        
        generalServices.updatePricingPlanDetails(updateData).then(function scb(resp){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(resp.status,200))
            {
                if (resp.data.data.message != 'No data found.') {
                    $scope.userPricingPlan = resp.data.data.myPlan
                    $scope.pricingPlansDetails = resp.data.data.otherPlans;
                    $scope.msg=resp.data.data.message;
                    $scope.showModal=true;
                }
            } else if(angular.equals(resp.data.code,401)){
                $scope.msg=resp.data.message;
                $scope.showModal=true;
            }
            else {
                $scope.msg="Something went wrong ... Try again !!!";
                $scope.showModal=true;
            }
        
        },function ecb(err){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
          });         
    }


// Billing and Invoice related code
    $scope.billingDetails = {
        "billingName":"",
        "emailAddress":"",
        "contactNumber":"",
        "companyName":"",
        "addressStreet1":"",
        "addressStreet2":"",
        "city":"",
        "state":"",
        "country":"",
        "pincode":""
    };

    $scope.getUserBillingDetails = function(){
        $scope.showprogress = true;
        $scope.loadingclass = true;
        generalServices.getUserBillingDetails($scope.userId).then(function scb(billingResponse){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(billingResponse.status,200))
            {
                if (billingResponse.data.data.message != 'No data found.') {
                    $scope.billingDetails = billingResponse.data.data.billingDetails;
                    $scope.msg=billingResponse.data.data.message;
                    $scope.showModal=true;
                }
            } else if(angular.equals(billingResponse.data.code,401)){
                $scope.msg=billingResponse.data.message;
                $scope.showModal=true;
            }
            else {
                $scope.msg="Something went wrong ... Try again !!!";
                $scope.showModal=true;
            }
        }, function ecb(billingDetailsError){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
        });
    }

    $scope.updateBillingDetails = function(){
        $scope.showprogress = true;
        $scope.loadingclass = true;
        generalServices.updateUserBillingDetails($scope.userId,$scope.billingDetails).then(function scb(resp){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(resp.status,200))
            {
                if (resp.data.data.message != 'No data found.') {
                    $scope.msg=resp.data.data.message;
                    $scope.showModal=true;
                }
            } else if(angular.equals(resp.data.code,401)){
                $scope.msg=resp.data.message;
                $scope.showModal=true;
            }
            else {
                $scope.msg="Something went wrong ... Try again !!!";
                $scope.showModal=true;
            }
        }, function ecb(err){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
        });        
    }

    
    $scope.getUsersInvoiceDetails = function(showResponseModalFlag){
        $scope.pendingInvoiceSet = [];
        $scope.archiveInvoiceSet = [];
        let tempInvoiceList = [];
        $scope.showprogress = true;
        $scope.loadingclass = true;
        generalServices.getUserInvoiceDetails($scope.userId).then(function scb(resp){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(resp.status,200))
            {
                if (resp.data.data.message != 'No data found.') {
                    tempInvoiceList = resp.data.data.invoiceLists;
                    for(let invoiceCount=0; invoiceCount<tempInvoiceList.length; 
                        invoiceCount++){
                        if(tempInvoiceList[invoiceCount].status!='paid'){
                            $scope.pendingInvoiceSet.push(tempInvoiceList[invoiceCount]);
                        } else {
                            $scope.archiveInvoiceSet.push(tempInvoiceList[invoiceCount]);
                        }
                    }
                    if(showResponseModalFlag){
                        $scope.msg=resp.data.data.message;
                        $scope.showModal=true;
                    }
                    
                }
            } else if(angular.equals(resp.data.code,401)){
                    if(showResponseModalFlag){
                        $scope.msg=resp.data.message;
                        $scope.showModal=true;
                    }
            }
            else {
                    if(showResponseModalFlag){
                        $scope.msg="Something went wrong ... Try again !!!";
                        $scope.showModal=true;
                    }  
            }
        }, function ecb(err){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
        });        
    }

// handles the payment return page view
    let paymentResponse = {};
    let paymentMessage = "";
    if($location.search().query == "cfreturn"){
        paymentResponse = $location.search();
        $scope.userTab = "inv";
        $scope.getUsersInvoiceDetails(false);
        if(paymentResponse.transactionStatus == "SUCCESS"){
            paymentMessage = "Payment is successfull.Thanks for your support!! You can check the details in the Invoice section."
        } else if(paymentResponse.transactionStatus == "FAILED") {
            paymentMessage = "Payment is failed due to some technical reasons.Our support staff will get in touch with you to take it forward.Meanwhile you can retry the payment by clicking on the RETRY button below"
        } else if(paymentResponse.transactionStatus == "CANCELLED") {
            paymentMessage = "Payment is cancelled by you.Our support staff will get in touch with you to take it forward.Meanwhile you can retry the payment by clicking on the RETRY button below"
        } else if(paymentResponse.transactionStatus == "PENDING") {
            paymentMessage = "Payment is not yet done.This might be due to overload usage at the payment gateway level. You will be notified once the payment is processed."
        }
        $scope.msg=paymentMessage;
        $scope.showModal=true;
    }

    $scope.initPayment = function(invoiceData){
        $scope.showprogress = true;
        $scope.loadingclass = true;
        let paymentGatewayData = {
            Url:"",
            method:"post",
            params:{}
        };
        let invoiceSelectedData = {
            invoiceId: invoiceData.invoiceId,
            amount: invoiceData.amount,
            startDate: invoiceData.startDate,
            endDate: invoiceData.endDate,
            status: invoiceData.status
        };
        generalServices.initPaymentService(invoiceSelectedData).then(function scb(resp){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(resp.status,200))
            {
                if (resp.data.data.message != 'No data found.') {
                    let responseData = resp.data.data;
                    if(responseData.transactionType === "new"){
                        paymentGatewayData.Url= resp.data.data.gatewayUrl;
                        paymentGatewayData.params = resp.data.data.gatewayData;
                        $rootScope.$broadcast('gateway-redirect', paymentGatewayData);
                    } else if(responseData.transactionType === "existing"){
                        $window.location.href = responseData.transactionData.paymentLink;
                    }

                }
            } else if(angular.equals(resp.data.code,401)){
                $scope.msg=resp.data.message;
                $scope.showModal=true;
            }
            else {
                $scope.msg="Something went wrong ... Try again !!!";
                $scope.showModal=true;
            }
        }, function ecb(err){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
        });        
    }

    /* GET User preference Details*/
    $scope.userPreferenceList = [];
    $scope.selectedPreferences = [];
    $scope.getUserPreferenceDetails = function(){
        $scope.showprogress = true;
        $scope.loadingclass = true;
        let serviceData = {userId:$scope.userId};
        generalServices.getUserPrefrences(serviceData).then(function scb(resp){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(resp.status,200))
            {
                if (resp.data.data.message != 'No data found.') {
                    let responseData = resp.data.data;
                    $scope.userPreferenceList = responseData;
                }
            } else if(angular.equals(resp.data.code,401)){
                $scope.msg=resp.data.data.message;
                $scope.showModal=true;
            }
            else {
                $scope.msg="Something went wrong ... Try again !!!";
                $scope.showModal=true;
            }
        }, function ecb(err){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
        });
    }

    /* UPDATE User preference Details*/
    $scope.updateUserPreferences = function(){
        $scope.showprogress = true;
        $scope.loadingclass = true;
        let sanitizeDataSchema = angular.copy($scope.userPreferenceList);
        for(let preferenceCount=0; preferenceCount<sanitizeDataSchema.length; preferenceCount++){
            delete sanitizeDataSchema["$$hashKey"];
        }
        let serviceData = {
            userId:$scope.userId,
            preferenceList: sanitizeDataSchema
        };
        for(let prefCount=0; prefCount<serviceData.preferenceList.length; prefCount++){
            if(serviceData.preferenceList[prefCount].preferenceId == "PRE-0001"){
                $scope.getNeedTutor = serviceData.preferenceList[prefCount].status;
            } else if (serviceData.preferenceList[prefCount].preferenceId == "PRE-0002"){
                $scope.getVideoSupport = serviceData.preferenceList[prefCount].status;
            }
        }
        generalServices.updatesUserPreferences(serviceData).then(function scb(resp){
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if(angular.equals(resp.status,200))
            {
                if (resp.data.data.message != 'No data found.') {
                    SharedData.updateUserPreferencesData(
                        $scope.getNeedTutor,
                        $scope.getVideoSupport
                    );
                    ipCookie("needTutor", $scope.getNeedTutor, { path: '/', expires: 1 });
                    ipCookie("needVideoSupport", $scope.getVideoSupport, { path: '/', expires: 1 });
                    $scope.msg=resp.data.data.message;
                    $scope.showModal=true;
                }
            } else if(angular.equals(resp.data.code,401)){
                $scope.msg=resp.data.data.message;
                $scope.showModal=true;
            }
            else {
                $scope.msg="Something went wrong ... Try again !!!";
                $scope.showModal=true;
            }
        }, function ecb(err){
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg="Error.Try again";
                $scope.showModal=true;
        });        
    }

    $scope.converToK = function(transactions){
        let inThousands = transactions/1000;
        let inMillions = transactions/1000000
        if(inMillions>=1){
            return inMillions+"Million";
        } else {
            return inThousands+"K";
        }
    }

}]);
MainApp.directive(
    'modali',
    function() {
        return {
            template: '<div class="modal fade" >' + '<div class="modal-dialog modal-sm">' + '<div class="modal-content" style="margin-top: 24%;">' + '<div class="modal-header">'

                + '<h4 class="modal-title" style="margin-left:40%;  color:white;"></h4>' + '</div>' + '<div class="modal-body" style="height:320px; width:700px;" ng-transclude>  </div>' + '<button style="margin-left:35%;"  type="button" ng-click="save(customModel);" class="btn btn-primary" data-dismiss="modal">Save</button>' + '</div>' + '</div>' + '</div>',
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: true,
            link: function postLink(scope, element, attrs) {

                scope.$watch(attrs.visible, function(value) {

                    if (value == true) {
                        $(element).modal('show');
                    } else {
                        $(element).modal('hide');
                    }

                });

                $(element).on('shown.bs.modal', function() {
                    scope.$apply(function() {
                        scope.$parent[attrs.visible] = true;
                    });
                });

                $(element).on('hidden.bs.modal', function() {
                    scope.$apply(function() {
                        scope.$parent[attrs.visible] = false;
                    });
                });


            }
        };
    });

// MainApp.directive('autoSubmitForm', ['$timeout','$sce', function($timeout,$sce) {
//     return {
//         replace: true,
//         scope: {},
//         template: '<form action="{{ paymentFormUrl }}" method="{{ paymentFormData.method }}">' +
//                   ' <div ng-repeat="(key,val) in paymentFormData.params">' +
//                   '     <input type="hidden" name="{{ key }}" value="{{ val }}" />' +
//                   ' </div>' +
//                   '</form>',
//         link: function($scope, element, $attrs) {
//             $scope.$on('gateway-redirect', function(event, data) {
//                 $scope.paymentFormData = data;
//                 $scope.paymentFormUrl = $sce.trustAsResourceUrl(data.Url);
//                 $timeout(function() {
//                     element.submit();
//                 })
//              })
//         }
//     }
// }]);
