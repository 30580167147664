MainApp.factory('connectServices', ['AppConstants', '$q', '$http', function(AppConstants, $q, $http) {
    return {
        getApiDetailsConsumer: function(apiId) {
            var url = "/connect/consumers/apidetails/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApiResourceConsumer: function(apiId) {
            var url = "/connect/consumers/apiresources/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getResourceClassesConsumer: function(resourceId) {
            var url = "/connect/consumers/resourceclasses/" + resourceId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApiCurlTemplateConsumer: function(resourceId) {
            var url = "/connect/consumers/api/preview/" + resourceId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApiPricePlansConsumer: function(apiId) {
            var url = "/connect/consumers/pricing/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        subscribeApi: function(dataToServer) {
            var url = "/connect/consumers/api/subscribe";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        unSubscribeApi: function(dataToServer) {
            var url = "/connect/consumers/api/unsubscribe";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getAuthDetails: function(apiId) {
            var url = "/connect/auths/consumers/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        addConsumerAuthentication: function(apiId, dataToServer) {
            var url = "/connect/auths/consumers/" + apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },  
        addConsumerOauth1Authentication: function(dataToServer) {
            var url = dataToServer.parameters.requestTokenUrl;
            var op = "get";
            var data = dataToServer;
            return $http.post(AppConstants.api_preview_oauth1_endpoint,JSON.stringify({ url: url, operation: op, data: data}))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        addConsumerOauth1AccessTokenAuthentication: function(dataToServer) {
            var url = "";
            var op = "get";
            var data = dataToServer;
            return $http.post(AppConstants.api_preview_oauth1_access_token_endpoint,JSON.stringify({ url: url, operation: op, data: data}))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        addCollaborator: function(apiId, dataToServer) {
            var url = "/connect/collaboration/" + apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        deleteCollaborator: function(apiId, dataToServer) {
            var url = "/connect/collaboration/" + apiId;
            var op = "delete";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getCollaborators: function(apiId) {
            var url = "/connect/collaboration/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getUsersToCollaborate: function(apiId) {
            var url = "/connect/collaboration/users/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        addCustomPlan: function(apiId, dataToServer) {
            var url = "/connect/custompricing/" + apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getPricePlanDetails: function(pricePlanId) {
            var url = "/connect/pricing/details/" + pricePlanId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        directSubscription: function(dataToServer) {
            var url = "/connect/consumers/api/subscribe/free";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        unSubscribePlan: function(dataToServer) {
            var url = "/connect/consumers/api/unsubscribe/free";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        upVoteAnApi: function(dataToServer,apiId) {
            var url = "/content/voteapi/"+apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        downVoteAnApi: function( dataToServer,apiId) {
            var url = "/content/voteapi/"+apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApiVotes: function(apiId) {
            var url = "/content/voteapi/"+apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        followApi: function(dataToServer,apiId) {
            var url = "/content/followapi/"+apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        unFollowAPI: function( dataToServer,apiId) {
            var url = "/content/followapi/"+apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApiFollowersNum: function(apiId) {
            var url = "/content/followapi/"+apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        processEnquiry: function(data) {
            var url = "/connect/contact";
            var op = "post";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'without'}))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getApiMetrics: function() {
            var url = "/content/public/ypmetrics";
            var op = "post";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data, accessToken: 'public'}))
                .then(function scb(resp) {
                    console.log(resp);
                    return resp;
                }, function ecb(err) {
                    console.log(err);
                    return err;
                });
        },
        processSsoProfile: function(data) {
            return $http.post(AppConstants.api_sso_process,JSON.stringify({data:data}))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });            
        },
    }
}]);
