MainApp.controller("YappesHomeCntrl", ['$scope', '$rootScope', '$location', 'HeaderVisibility', '$routeParams', 'generalServices', 'setEmailId', 'localStorageService', '$timeout', 'ipCookie', 'SharedData', 'AppConstants', '$sce',
    function ($scope, $rootScope, $location, HeaderVisibility, $routeParams, generalServices, setEmailId, localStorageService, $timeout, ipCookie, SharedData, AppConstants, $sce) {
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.loadingmessage = "Loading ...";
        $timeout(function () {
            $scope.showprogress = false;
            $scope.loadingclass = false;
        }, 2000);
        if (SharedData.userData.emailId != '') {
            $scope.emailId = SharedData.userData.emailId;
            $scope.userName = SharedData.userData.userName;
            $scope.getNeedTutor = SharedData.userData.needTutor;
            $scope.getVideoSupport = SharedData.userData.needVideoSupport;
        } else {
            $scope.emailId = ipCookie("userEmail");
            $scope.userName = ipCookie("userName");
            $scope.getNeedTutor = ipCookie('needTutor');
            $scope.getVideoSupport = ipCookie('needVideoSupport');
        }
        $scope.userTab = 'mylicense';
        $scope.videoUrl = $sce.trustAsResourceUrl(AppConstants.videoSupportSets.home);
        $scope.$emit('userName', $scope.userName);
        $scope.showLoginHeader = HeaderVisibility.getLoginHeader();
        $scope.showProfileHeader = HeaderVisibility.getProfileHeader();
        $scope.$emit('showLoginHeader', $scope.showLoginHeader);
        $scope.$emit('showProfileHeader', $scope.showProfileHeader);
        $scope.$emit('emailId', $scope.emailId);
        $scope.recievedApiCount = 0;
        $scope.showMoreApi = true;
        $scope.noMoreApi = false;
        $scope.showVideoSupportOverlay = false;
        $scope.showOverlay = false;
        $scope.setHeight = 550;
        $scope.startIndex = 0;
        $scope.dataToServer = {};
        $scope.boundary = 9;
        $scope.cachmem = [];
        $scope.backNavigation = {
            "previous": "homepage",
            "searchfiltered": "no",
            "filteredTag": ""
        };
        $scope.viewName = "";
        $scope.viewPath = "";
        $scope.relevanceArticleSets = [{
            "name": "searchAPI",
            "indexLable": "Search an API",
            "videoUrl": "",
            "videoTitle": "",
            "paragraph": "",
            "steps": ["step1", "step2", "step3"]
        }];
        $scope.allArticleSets = [{
            "name": "searchAPI",
            "indexLable": "Search an API",
            "videoUrl": "",
            "videoTitle": "",
            "paragraph": "",
            "steps": ["step1", "step2", "step3"]
        }];
        $scope.showHelpContents = false;

        localStorageService.set("navigationState", $scope.backNavigation);

        $timeout(function () {
            if ($scope.getNeedTutor) {
                $scope.CallMe();
            }
            if ($scope.getVideoSupport) {
                $scope.showVideoSupportOverlay = true;
            }

        }, 2500);

        window.scrollTo(0, 0);
        $scope.IntroOptions = {
            tooltipPosition: 'top',
            steps: [{
                element: '#headerstep1',
                intro: "<div class='tour-header'>Profile</div><hr>" + "<div class='tour-step'>Edit and Manage your profile information</div>",
                position: 'bottom'
            }, {
                element: '#headerstep4',
                intro: "<div class='tour-header'>Feedback</div><hr>" + "<div class='tour-step'>Any Suggestions/Issues contact us using the Feedback Form</div>",
                position: 'bottom'
            }, {
                element: '#yappesDocs',
                intro: "<div class='tour-header'>Documentation</div><hr>" + "<div class='tour-step'>Documentation of Yappes</div>",
                position: 'bottom'
            }, {
                element: '#headerstep2',
                intro: "<div class='tour-header'>Dashboard</div><hr>" + "<div class='tour-step'>Create and Manage Your APIs</div>",
                position: 'bottom'
            }, {
                element: '#headerstep3',
                intro: "<div class='tour-header'>Discovery</div><hr>" + "<div class='tour-step'>Explore APIs comprehensively</div>",
                position: 'bottom'
            },

            {
                element: document.querySelector('#step5'),
                intro: "<div class='tour-header'>Filters</div><hr>" + "<div class='tour-step'>Filter the APIs based on the Services they provide</div>",
                position: 'right'
            },

            {
                element: document.querySelector('#newstep'),
                intro: "<div class='tour-header'>Build Your API</div><hr>" + "<div class='tour-step'>Build your API from Scratch using your own business logic and data</div>",
                position: 'bottom'
            },
            {
                element: document.querySelector('#newstep1'),
                intro: "<div class='tour-header'>Onboard Your API</div><hr>" + "<div class='tour-step'>Already have your API. Then onboard to our platform</div>",
                position: 'bottom'
            },
            {
                element: document.querySelector('#newstep2'),
                intro: "<div class='tour-header'>Search API</div><hr>" + "<div class='tour-step'>Discover APIs Based on their Names</div>",
                position: 'bottom'
            },
            {
                element: document.querySelector('#newsteprefreshbutton'),
                intro: "<div class='tour-header'>Reset Search</div><hr>" + "<div class='tour-step'>Use this feature to show all APIs</div>",
                position: 'bottom'
            },
            {
                element: document.querySelectorAll('#step6')[0],
                intro: "<div class='tour-header'>APIs List</div><hr>" + "<div class='tour-step'>List of APIs available in the platform</div>",
                position: 'left'
            }
            ],
            showStepNumbers: false,
            showBullets: false,
            exitOnOverlayClick: true,
            exitOnEsc: true,
            nextLabel: '<strong>NEXT!</strong>',
            prevLabel: '<span style="color:green">Previous</span>',
            skipLabel: 'Exit',
            doneLabel: 'Thanks'
        };
        $scope.ChangeEvent = function (targetElement, scope) {
            window.scrollTo(0, 0);
        };

        $scope.selectedTag = "";
        let apiNameSchema = { "searchName": "", "index": $scope.startIndex, "boundary": $scope.boundary, "userId": $scope.userId, "location": "homePage" };
        let searchNameTemp = [];

        $scope.viewGroup = function (productId) {
            var url = "/license/";
            $location.path(url);
        }
        $scope.productList = [];
        $scope.getProductList = function () {
            $scope.showprogress = true;
            $scope.loadingclass = true;
            generalServices.getProductList().then(function scb(resp) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                if (angular.equals(resp.data.code, 200)) {
                    $scope.productList = resp.data.data;
                } else if (angular.equals(resp.data.code, 401)) {
                    $scope.msg = resp.data.message;
                    $scope.showFeedModal = true;
                } else {
                    $scope.msg = "Product list is not available. Please contact the Yappes team for further clarification.";
                    $scope.showFeedModal = true;
                }

            }, function ecb(err) {
                $scope.msg = "Product list is not available. Please contact the Yappes team for further clarification.";
                $scope.showFeedModal = true;
                $scope.showprogress = false;
                $scope.loadingclass = false;
            });
        }

        $scope.productLicenseToSend = {
            "productName": "",
            "productId": "",
            "licenseType": "",
            "duration": ""
        }

        $scope.typeDuration = {
            "type": "",
            "count": "",
            "submittedDateTime": ""
        }

        $scope.showBackToFlag = false;

        $scope.backToProductList = function () {
            $scope.showBackToFlag = false;
        }

        $scope.versionsList = [];
        $scope.durationTypes = ['day', 'month'];
        $scope.licenseType = [];
        $scope.version = { "selected": "" };
        $scope.licensetype = { "selected": "" };
        $scope.durationType = { "selected": "" };
        $scope.durationType.selected = $scope.durationTypes[0];

        $scope.staticDuration = {};

        $scope.purchaseCoonfig = {};

        $scope.getPurcahseOrderConfigs = function () {
            generalServices.getPurcahseOrderConfigs().then(function scb(resp) {
                if (resp.data.code == 400) {
                    $scope.msg = resp.data.data.message;
                    $scope.showModal = true;
                } else if (resp.data.code == 200) {
                    $scope.staticDuration = resp.data.data.durations[0]; 
                    $scope.versionsList = resp.data.data.availableVersions;
                    $scope.version.selected = $scope.versionsList[0];
                    $scope.licenseType = [$scope.staticDuration.licenseType];
                    $scope.licensetype.selected = $scope.licenseType[0];
                }
            }, function ecb(err) {
                $scope.msg = "Something went wrong !!";
                $scope.showModal = true;
            });
        }
        
        $scope.getPurcahseOrderConfigs();

        const getLicenseType = (data) => {
            let temp = "";
            $scope.licenseType.forEach((element) => {
                if (data == element) {
                    temp = element;
                }
            });
            return temp;
        }

        $scope.productLicense = function (productDetails) {
            $scope.showBackToFlag = true;
            $scope.productLicenseToSend.productId = productDetails.productId;
            $scope.productLicenseToSend.productName = productDetails.productName;
        }

        $scope.clickPurchase = function () {
            $scope.showprogress = true;
            $scope.loadingclass = true;
            $scope.typeDuration.type = $scope.durationType.selected;
            $scope.productLicenseToSend.duration = $scope.typeDuration;
            $scope.productLicenseToSend.licenseType = $scope.licensetype.selected;
            $scope.productLicenseToSend.duration.submittedDateTime = new Date();
            $scope.productLicenseToSend.version = $scope.version.selected;
            $scope.productLicenseToSend.licenseType = getLicenseType($scope.licensetype.selected);
            setTimeout(() => {
                generalServices.purchaseLicense($scope.productLicenseToSend).then(function scb(resp) {
                    if (resp.data.code == 400) {
                        $scope.showprogress = false;
                        $scope.loadingclass = false;
                        $scope.msg = resp.data.data.message;
                        $scope.showModal = true;
                    } else if (resp.data.code == 200) {
                        $scope.msg = resp.data.data.message;
                        $scope.showModal = true;
                        $scope.showprogress = false;
                        $scope.loadingclass = false;
                        $scope.showBackToFlag = false;
                        $scope.onClickGetPurchasedLicense();
                    }
                }, function ecb(err) {
                    $scope.msg = "Something went wrong !!";
                    $scope.showModal = true;

                    $scope.showprogress = false;
                    $scope.loadingclass = false;

                    $scope.onClickGetPurchasedLicense();
                    if ($scope.productLicenseToSend.licenseType != 'free-trial') {
                        $scope.intiatePayment(resp.data.data);
                    }
                })
            }, 2000);
        }





$scope.getPurchaseList = function () {
    generalServices.getPurcahseList().then(function scb(resp) {
        if (resp.data.code == 400) {
            $scope.msg = resp.data.data.message;
            $scope.showModal = true;
        } else if (resp.data.code == 200) {
            $scope.purchaseList = resp.data.data;
        }
    }, function ecb(err) {
        $scope.msg = "Something went wrong !!";
        $scope.showModal = true;
    });
}

// handles the payment return page view
let paymentResponse = {};
let paymentMessage = "";
if ($location.search().query == "cfreturn") {
    paymentResponse = $location.search();
    // $scope.userTab = "inv";
    //$scope.getUsersInvoiceDetails(false);
    if (paymentResponse.transactionStatus == "SUCCESS") {
        paymentMessage = "Payment is successfull.Thanks for your support!! You can check the details in the Invoice section."
    } else if (paymentResponse.transactionStatus == "FAILED") {
        paymentMessage = "Payment is failed due to some technical reasons.Our support staff will get in touch with you to take it forward.Meanwhile you can retry the payment by clicking on the RETRY button below"
    } else if (paymentResponse.transactionStatus == "CANCELLED") {
        paymentMessage = "Payment is cancelled by you.Our support staff will get in touch with you to take it forward.Meanwhile you can retry the payment by clicking on the RETRY button below"
    } else if (paymentResponse.transactionStatus == "PENDING") {
        paymentMessage = "Payment is not yet done.This might be due to overload usage at the payment gateway level. You will be notified once the payment is processed."
    }
    $scope.msg = paymentMessage;
    $scope.showModal = true;
}

$scope.intiatePayment = function (data) {
    $scope.showprogress = true;
    $scope.loadingclass = true;
    let paymentGatewayData = {
        Url: "",
        method: "post",
        params: {}
    };

    let selectedData = {
        "purchaseId": data.purchaseId,
        "amount": "1.0",

    }
    generalServices.initPaymentService(selectedData).then(function scb(resp) {
        $scope.showprogress = false;
        $scope.loadingclass = false;
        if (angular.equals(resp.status, 200)) {

            let responseData = resp.data.data;
            if (responseData.transactionType === "new") {
                paymentGatewayData.Url = resp.data.data.gatewayUrl;
                paymentGatewayData.params = resp.data.data.gatewayData;
                $rootScope.$broadcast('gateway-redirect', paymentGatewayData);
            } else if (responseData.transactionType === "existing") {
                $window.location.href = responseData.transactionData.paymentLink;
            }
        } else if (angular.equals(resp.data.code, 401)) {
            $scope.msg = resp.data.message;
            $scope.showModal = true;
        }
        else {
            $scope.msg = "Something went wrong ... Try again !!!";
            $scope.showModal = true;
        }
    }, function ecb(err) {
        $scope.showprogress = false;
        $scope.loadingclass = false;
        $scope.msg = "Error.Try again";
        $scope.showModal = true;
    });
}

$scope.purchaseLicenseList = [];

$scope.onClickGetPurchasedLicense = function () {
    generalServices.getPurchasedLicense().then(function scb(resp) {
        if (resp.data.code == 400) {
            $scope.msg = resp.data.data.message;
            $scope.showModal = true;
        } else if (resp.data.code == 200) {
            if(resp.data.data.hasOwnProperty('purchaseList')){
                $scope.purchaseLicenseList = resp.data.data.purchaseList;
            } else {
                $scope.purchaseLicenseList = [];
            }
            
        }
    }, function ecb(err) {
        $scope.msg = "Something went wrong !!";
        $scope.showModal = true;
    });
}

$scope.viewLicenseModalFlag = false;
$scope.onClickLicenseViewModal = (licenseDetails) => {
    $scope.viewLicenseModalFlag = true;
    $scope.buttonClicked = "Purchase Order Details";
    $scope.onClickGetSinglePurchasedLicens(licenseDetails);
}

$scope.viewLicenseDetails = {
    purchaseId: "",
    productId: "",
    productName: "",
    version: "",
    licenseType: "",
    duration: ""
};

$scope.onClickGetSinglePurchasedLicens = function (licenseDetails) {
    $scope.tempModelForLicense = licenseDetails;
    $scope.viewLicenseDetails.purchaseId = licenseDetails.purchaseId;
    $scope.viewLicenseDetails.productId = licenseDetails.productDetails.productId;
    $scope.viewLicenseDetails.productName = licenseDetails.productDetails.productName;
    $scope.viewLicenseDetails.version = licenseDetails.productDetails.version;
    $scope.viewLicenseDetails.licenseType = licenseDetails.productDetails.licenseType;
    $scope.viewLicenseDetails.duration = licenseDetails.productDetails.duration;
}

$scope.onClickOk = () => {
    $scope.viewLicenseModalFlag = false;
}

$scope.onClickGetPurchasedLicense();

$scope.onClickDownloadLicense = (purchaseDetails) => {
    if ($scope.tempModelForLicense.hasOwnProperty('licenseDetails')) {
        let atrib = document.createElement('a')
        let blob = new Blob([$scope.tempModelForLicense.licenseDetails.licenseKey], { type: 'text/strings' });
        let url = URL.createObjectURL(blob)
        atrib.setAttribute('href', url)
        atrib.setAttribute('download', purchaseDetails.purchaseId + '.lic')
        atrib.click()
    }
}

// Making the Category Box Top Fix
$(window).scroll(function () {
    if ($(window).scrollTop() > 116) {
        angular.element(document.getElementById('categoryBoxScroll')).addClass('before-scroll');
        angular.element(document.getElementById('categoryBoxScroll')).removeClass('after-scroll');
    } else {
        angular.element(document.getElementById('categoryBoxScroll')).addClass('after-scroll');
        angular.element(document.getElementById('categoryBoxScroll')).removeClass('before-scroll');
    }
    $scope.$apply();
});
    }
]);

MainApp.filter('changeTheDateForm', function () {
    return function (x) {
        if (x === undefined) {
            return;
        }
        let formedDate = new Date(x.submittedDateTime);
        x.submittedDateTime = new Date(x.submittedDateTime);
        if (x.type == 'days') {
            formedDate = formedDate.setDate(x.submittedDateTime.getDate() + x.count);
        } else if (x.type == 'months') {
            formedDate = formedDate.setMonth(x.submittedDateTime.getMonth() + x.count);
        } else {
            formedDate = formedDate.setYear(x.submittedDateTime.getYear() + x.count);
        }
        formedDate = new Date(formedDate);
        return formedDate.toISOString().split("T")[0];
    }
});

MainApp.directive('autoSubmitForm', ['$timeout', '$sce', function ($timeout, $sce) {
    return {
        replace: true,
        scope: {},
        template: '<form action="{{ paymentFormUrl }}" method="{{ paymentFormData.method }}">' +
            ' <div ng-repeat="(key,val) in paymentFormData.params">' +
            '     <input type="hidden" name="{{ key }}" value="{{ val }}" />' +
            ' </div>' +
            '</form>',
        link: function ($scope, element, $attrs) {
            $scope.$on('gateway-redirect', function (event, data) {
                $scope.paymentFormData = data;
                $scope.paymentFormUrl = $sce.trustAsResourceUrl(data.Url);
                $timeout(function () {
                    element.submit();
                })
            })
        }
    }
}]);

