MainApp.controller("SignInCtrl", ['$scope', 'generalServices', 'HeaderVisibility', 'AppConstants', 'ipCookie', 'SharedData', '$location','localStorageService','$rootScope', function($scope, generalServices, HeaderVisibility, AppConstants, ipCookie, SharedData, $location,localStorageService,$rootScope) {
    $scope.showLoginHeader = true;
    $scope.showProfileHeader = false;
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    $scope.loadingmessage = "Loading ...";
    $scope.setHeight = 300;
    $scope.YappesLogin = {
        "emailId": "",
        "password": ""
    };
    $scope.tutorList = {};
    $scope.tutorList["PRE-0001"] = false;
    $scope.tutorList["PRE-0002"] = false;
    let userPreferenceList = [];
    $scope.enableGitHubSso = true;
    $scope.SignIn = function() {
        $scope.loadingmessage = AppConstants.login_operation;
        $scope.showprogress = true;
        $scope.loadingclass = true;
        setTimeout(function() {
            generalServices.userLogin($scope.YappesLogin).then(function scb(resp) {
                $scope.emailId = "";
                $scope.msg = "";

                if (angular.equals(resp.data.code, 200)) {
                    $scope.emailId = resp.data.data.emailId;
                    $scope.msg = resp.data.message;
                    $scope.showLoginHeader = HeaderVisibility.getLoginHeader();
                    $scope.showProfileHeader = HeaderVisibility.getProfileHeader();
                    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                    ipCookie('userId', resp.data.data.userId, { path: '/',expires: 1 });
                    ipCookie("userEmail", $scope.emailId, { expires: 1 });
                    ipCookie("firstName", resp.data.data.firstName, { expires: 1 });
                    ipCookie("lastName", resp.data.data.lastName, { expires: 1 });
                    ipCookie("userName", resp.data.data.userName, { expires: 1 });

                    SharedData.updateUserData(
                        resp.data.data.userName,
                        resp.data.data.userId,
                        resp.data.data.emailId,
                        resp.data.data.firstName,
                        resp.data.data.lastName,
                    );
                    if(localStorageService.get("apishare")==null || angular.isUndefined(localStorageService.get("apishare")))
                    {
                    	var url = "yappes_home/" + $scope.emailId;
                        $location.path(url);
                    }
                    else
                    {
                    	var apishare=localStorageService.get("apishare");
                   	 localStorageService.remove("apishare");
                   	 $scope.backNavigation = {
                                "previous": "homepage",
                                "searchfiltered": "no",
                                "filteredTag": ""
                            };
                       localStorageService.set("navigationState", $scope.backNavigation);
                   	var url = apishare.referenceValue +"/"+$scope.emailId;
                       $location.path(url);
                    	
                    }
                    
                } else if (angular.equals(resp.data.code, 400)) {
                    $scope.msg = resp.data.message;
                    $scope.showLoginHeader = true;
                    $scope.showProfileHeader = false;
                    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                    $scope.showModal = true;
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                } else if (angular.equals(resp.data.code, 401)) {
                    $scope.msg = resp.data.message;
                    $scope.showLoginHeader = true;
                    $scope.showProfileHeader = false;
                    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                    $scope.showModal = true;
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                } else {
                    $scope.msg = "Invalid Email Id/Password";
                    $scope.showLoginHeader = true;
                    $scope.showProfileHeader = false;
                    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
                    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
                    $scope.showModal = true;
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                }

            }, function ecb(err) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = "Login Failed";
                $scope.showModal = true;
            });
        }, 2000);
    }
    $scope.close = function() {
        if (angular.equals($scope.code, 200)) {
            var url = "login";
            $location.path(url);
        }
    }
    $scope.signUp = function() {
        $location.path('signup');
    }

    $scope.forgotPasswordPopUp = function() {
        $scope.showpwdresetModal = true;
    }
    $scope.sendMailForPwdReset = function(email) {
        $scope.loadingmessage = AppConstants.reset_password_mail;
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.data = { "emailId": email }
        generalServices.sendMailForPasswordReset($scope.data).then(function scb(resp) {
            $scope.msg = "";
            $scope.loadingclass = false;
            $scope.showprogress = false;
            if (angular.equals(resp.data.code, 200)) {
                $scope.msg = resp.data.data.message;
                $scope.showModal = true;
            } else {
                $scope.msg = resp.data.message;
                $scope.showModal = true;
            }
        }, function ecb(err) {
            $scope.showprogress = false;
            $scope.loadingclass = false;
            $scope.msg = "mail Failed";
            $scope.showModal = true;
        });
    }
}]);
MainApp.directive(
    'modalpwd',
    function() {
        return {
            template: '<div class="modal fade">' + '<div class="modal-dialog modal-sm" style="width:350px;">' + '<div class="modal-content" style="border:1px solid #005095;margin-top:27%">' + '<div class="modal-body" ng-transclude>  </div>' + '</div>' + '</div>' + '</div>',
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: true,
            link: function postLink(scope, element, attrs) {
                scope.$watch(attrs.visible, function(value) {
                    if (value == true) {
                        $(element).modal('show');
                    } else {
                        $(element).modal('hide');
                    }
                });

                $(element).on('shown.bs.modal', function() {
                    scope.$apply(function() {
                        scope.inputEmail = "";
                        scope.$parent[attrs.visible] = true;
                    });
                });
                $(element).on('hidden.bs.modal', function() {
                    scope.$apply(function() {
                        scope.$parent[attrs.visible] = false;
                    });
                });
            }
        };
    });
