MainApp.controller("apiOauthCallbackController", ['$scope', 'generalServices', 'HeaderVisibility', 'AppConstants', 'ipCookie', 'SharedData', 'connectServices', '$window', '$location', 'localStorageService', function($scope, generalServices, HeaderVisibility, AppConstants, ipCookie, SharedData, connectServices, $window, $location, localStorageService) {
    $scope.showprogress = true;
    $scope.loadingclass = true;
    $scope.loadingmessage = "retrieving Access Token ...";
    $scope.getParameterByName = function(name, url) {
        if (!url) {
            url = $location.absUrl();
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    var urllocation = $location.absUrl();
    if(localStorageService.get("previewDetails")!=null || !angular.isUndefined(localStorageService.get("previewDetails"))){
        $scope.authenticationCookie = JSON.parse(JSON.stringify(ipCookie('previewDetails')));
    } else if(ipCookie('previewDetails')!=null){
        $scope.authenticationCookie = JSON.parse(JSON.stringify(ipCookie('previewDetails')));
    } else {
        $scope.authenticationCookie = "";
    }
    
    $scope.apiId = $scope.authenticationCookie['id'];
    $scope.emailId = $scope.authenticationCookie['emailId'];
    if ((urllocation.indexOf('code' + '=') != -1)) {
        $scope.field = 'code';
        $scope.code = $scope.getParameterByName($scope.field);
        $location.url($location.path());
        $scope.TokenParams = angular.copy($scope.authenticationCookie['authData']);
        $scope.TokenParams['parameters']['redirect_uri'] = $scope.TokenParams['parameters']['callbackUrl'];
        $scope.TokenParams['parameters']['code'] = $scope.code;
        delete $scope.TokenParams['authId'];
        delete $scope.TokenParams['parameters']['availableScope'];
        delete $scope.TokenParams['parameters']['accessTokenUrl'];
        delete $scope.TokenParams['parameters']['authorizationUrl'];
        delete $scope.TokenParams['parameters']['callbackUrl'];
        $scope.TokenParams['parameters'] = JSON.stringify($scope.TokenParams['parameters']);
        $scope.apiId = $scope.authenticationCookie['id'];
        $scope.emailId = $scope.authenticationCookie['emailId'];
        connectServices.addConsumerAuthentication($scope.apiId, $scope.TokenParams).then(function scb(resp) {
            if (angular.equals(resp.data.code, 200)) {
                if ($scope.authenticationCookie['preview'] == 'provider') {
                    ipCookie("redirection", true);
                    $window.sessionStorage.setItem('redirection', 'authorized');
                    var url = "api_Preview/" + $scope.apiId;
                    $location.path(url);

                } else if ($scope.authenticationCookie['preview'] == 'consumer') {
                    ipCookie("redirection_consumer", true);
                    $window.sessionStorage.setItem('redirection_consumer', 'consumerauthorized');
                    var url = "consumer_view/" + $scope.apiId + "/" + $scope.emailId;
                    $location.path(url);
                }

            } else if (angular.equals(resp.data.code, 400)) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = resp.data.message;
                $scope.showModal = true;
            } else if (angular.equals(resp.data.code, 401)) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = resp.data.message;
                $scope.showModal = true;
            } else {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                $scope.msg = "Something happened !!! Try again ...";
                $scope.showModal = true;
            }
        }, function ecb(err) {
            $scope.msg = err.data.message;
            $scope.showModal = true;
        });
    } else {
        $location.url($location.path());
        if ($scope.authenticationCookie['preview'] == 'provider') {
            ipCookie("redirection", true);
            $window.sessionStorage.setItem('redirection', 'authorized');
            var url = "api_Preview/" + $scope.apiId;
            $location.path(url);

        } else if ($scope.authenticationCookie['preview'] == 'consumer') {
            ipCookie("redirection_consumer", true);
            $window.sessionStorage.setItem('redirection_consumer', 'consumerauthorized');
            var url = "consumer_view/" + $scope.apiId + "/" + $scope.emailId;
            $location.path(url);
        }
    }
}]);
