MainApp.factory('Resources', ['AppConstants', '$q', '$http', function(AppConstants, $q, $http) {

    return {

        createNewResource: function(apiId, dataToServer) {
            var url = "/content/resources/" + apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllExistingEndPoints: function(apiId) {
            var url = "/content/resources/definitions/" + apiId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateResources: function(resourceId, dataToServer) {
            var url = "/content/resources/" + resourceId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        deleteResource: function(resourceId) {
            var url = "/content/resources/" + resourceId;
            var op = "delete";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
         getErrorSettings: function(apiId) {
            var url = "/content/apis/" +apiId+ "/errorinfo";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
         updateErrorSettings: function(apiId, dataToServer) {
            var url = "/content/apis/" + apiId + "/errorinfo/" + dataToServer.errorId;
            var op = "put";

            var data = {};
            if(dataToServer.userLevel == 'dev'){
                   data = {
                    'userLevel': dataToServer.userLevel,
                    'logLevel': dataToServer.logLevel
                };
            }else{
                data = {
                    'userLevel': dataToServer.userLevel,
                    'logLevel': dataToServer.logLevel,
                    'errorStatus' : dataToServer.errorStatus,
                    'errorMessage' : dataToServer.errorMessage
                };
            }           
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        saveHeaderInformation: function(resourceId, dataToServer) {
            var url = "/content/resources/classes/" + resourceId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllExistingHeaders: function(resourceId) {
            var url = "/content/resources/classes/" + resourceId;
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateHeaders: function(classId, dataToServer) {
            var url = "/content/resources/classes/" + classId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        deleteHeaders: function(classId) {
            var url = "/content/resources/classes/" + classId;
            var op = "delete";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateRouteMap: function(apiId, dataToServer) {
            var url = "/connect/passthrough/dnsroute/" + apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        checkRoute: function() {
            return $http.head(AppConstants.yappes_home_url).then(function scb(resp) {
                return resp;
            }, function ecb(err) {
                return err;
            });
        },
        previewApi: function(resourceId) {
            var url = "/connect/api/preview/" + resourceId + "?type=curl";
            var op = "get";
            var data = {};
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        executeCurlOperation: function(data) {
            return $http.post(AppConstants.api_curl_preview_endpoint, JSON.stringify({ data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });


        },
        addPrice: function(apiId, dataToServer) {
            var url = "/connect/pricing/" + apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        getAllPlans: function(apiId) {
            var url = "/connect/pricing/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
        },
        updatePricing: function(pricePlanId, dataToServer) {
            var url = "/connect/pricing/" + pricePlanId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        addAuthForAPIs: function(apiId, dataToServer) {
            var url = "/connect/auths/provider/" + apiId;
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAvailableAuthInfo: function(apiId) {
            var url = "/connect/auths/provider/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateAuthAPI: function(apiId, dataToServer) {
            var url = "/connect/auths/provider/" + apiId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        deleteAuthAPI: function(apiId) {
            var url = "/connect/auths/provider/" + apiId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getLicenseTermsCondtion: function(apiId) {
            var url = "/content/provider/api/terms/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getLicenseTermsCondtionConsumer: function(apiId) {
            var url = "/content/consumer/apipreview/terms/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        savelicenseTermsCondtion: function(apiId, dataToServer) {
            var url = "/content/provider/api/terms/" + apiId
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updatelicenseTermsCondtion: function(apiId, dataToServer) {
            var url = "/content/provider/api/terms/" + apiId
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllUploads: function(apiId) {
            var url = "/content/provider/revisions/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getrevisionStatistics: function(transactionId, ctype) {

            var url = "/content/provider/revisions/statistics/" + transactionId + "?changeType=" + ctype;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        submitFeedbackData: function(dataToServer) {
            var url = "/content/feedback/";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAplicationKeys: function() {
            var url = "/content/users/appkeys";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAboutAPIs: function(apiId) {
            var url = "/content/provider/api/readme/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAboutAPIsConsumer: function(apiId) {
            var url = "/content/consumer/apipreview/readme/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        saveAboutAnApi: function(apiId, dataToServer) {
            var url = "/content/provider/api/readme/" + apiId
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateAPIAboutContent: function(apiId, dataToServer) {
            var url = "/content/provider/api/readme/" + apiId
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllEnvironment: function(apiId) {
            var url = "/content/api/environment/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateEnvDetails: function(environmentId, dataToServer) {
            var url = "/content/api/environment/" + environmentId;
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllExisingQuota: function(apiId) {
            var url = "/connect/consumer/quota/" + apiId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateQuota: function(apiId, dataToServer) {
            var url = "/connect/consumer/quota/" + apiId
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getBusinessLogic: function(resourceId) {
            var url = "/content/newapi/logic/" + resourceId;
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        saveBusinessLogic: function(resourceId, dataToServer) {
            var url = "/content/newapi/logic/"+resourceId
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateBusinessLogic: function(resourceId, dataToServer) {
            var url = "/content/newapi/logic/"+resourceId
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        deleteBusinessLogic: function(resourceId) {
            var url = "/content/newapi/logic/"+resourceId
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        getAllRemoteEndpoints: function(apiId) {
            var url = "/content/api/"+apiId+"/remotes";
            var op = "get";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });
 
        },
        addRemoteEndpoint: function(apiId, dataToServer) {
            var url = "/content/api/"+apiId+"/remotes"
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        updateRemoteEndpoint: function(apiId,remoteId, dataToServer) {
            var url = "/content/api/"+apiId+"/remotes/"+remoteId
            var op = "put";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        addSecurityKeyData: function(apiId,dataToServer) {
            var url = "/content/api/"+apiId+"/remotes/securefiles";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        deleteSecurityKeyData: function(apiId,dataToServer) {
            var url = '/content/api/'+ apiId +'/remotes/securefiles/'+dataToServer.securityFileId;
            var op = "delete";
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        saveReport: function(apiId, dataToServer) {
            var url = "/content/apis/"+apiId+"/report";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        },
        fetchPtAnalytics: function(dataToServer) {
            var url = "/content/monitor/data/";
            var op = "post";
            var data = dataToServer;
            return $http.post(AppConstants.api_endpoint, JSON.stringify({ url: url, operation: op, data: data }))
                .then(function scb(resp) {
                    return resp;
                }, function ecb(err) {
                    return err;
                });

        }

    }
}])
