MainApp.controller("SignUpCtrl", ['$scope', 'generalServices', 'AppConstants', '$location', '$rootScope','localStorageService','ipCookie',function($scope, generalServices, AppConstants, $location, $rootScope,localStorageService,ipCookie) {
    $scope.showLoginHeader = true;
    $scope.showProfileHeader = false;
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    $scope.showModal = false;
    $scope.showTermsModalDialog = false;
    $scope.showTerms = false;
    $scope.buttonClicked = "";
    $scope.msg = "";
    $scope.code = "";
    $scope.loadingmessage = "Loading ...";
    $scope.setHeight = 300;
    var urllocation = $location.absUrl();
    $scope.enableGitHubSso = true; 
    $scope.getParameterByName = function(name, url) {
        if (!url) {
            url = $location.absUrl();
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    $scope.emailId = "";
    $scope.code = "";
    $scope.field = 'code';
    $scope.field2 = 'emailId';
    if (urllocation.indexOf('code' + '=') != -1) {
        $scope.code = $scope.getParameterByName($scope.field);
    }
    if (urllocation.indexOf('emailId' + '=') != -1) {
        $scope.email = $scope.getParameterByName($scope.field2);
        $scope.emailId = decodeURIComponent($scope.email);
    }
    $scope.YappesSignUp = {
    	"firstName":"",
    	"lastName":"",
        "emailId": $scope.emailId,
        "password": "",
        "confirmPassword": "",
        "organizationName": "",
        "clientIp": ""
       
    };


    $scope.SignUp = function() {
        $scope.loadingmessage = AppConstants.yappes_signup;
            $scope.showprogress = true;
            $scope.loadingclass = true;
            setTimeout(function() {
                generalServices.signUp($scope.YappesSignUp).then(function scb(resp) {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    $scope.code = resp.data.code;
                    if (angular.equals(resp.data.code, 200)) {
                        $scope.msg = resp.data.data.message;
                        $scope.showModal = true;

                    } else if (angular.equals(resp.data.code, 400)) {
                        $scope.msg = resp.data.message;
                        $scope.showModal = true;
                    } else if (angular.equals(resp.data.code, 409)){
                        $scope.msg = resp.data.data.message;
                        $scope.showModal = true;
                    } 
                    else {
                        $scope.msg = resp.data.message;
                        $scope.showModal = true;
                    }

                }, function ecb(err) {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    $scope.msg = "Something went wrong !!";
                    $scope.showModal = true;
                });
            }, 2000);
       
    }

    $scope.ssoAuthGithub=function(){
        localStorageService.set("ssoProfile", "ssoRegister");
        ipCookie("ssoProfile", "ssoRegister");
        $rootScope.$broadcast('sso-redirect', {"data":"empty"});  
    }
    
    $scope.close = function() {

        if (angular.equals($scope.code, 200)) {
            $location.url($location.path());
            var url = "login";
            $location.path(url);
        }

    }

    $scope.closeTermsPopUp = function() {
        $scope.showTerms = false;
        $scope.showTermsModalDialog = false;
    }

    $scope.showTermsAndConditions = function() {
        $scope.showTerms = true;
        $scope.showTermsModalDialog = true;
    };


}]);

MainApp.directive(
    'modals',
    function() {
        return {
            template: '<div class="modal fade alertdialog" >' + '<div class="modal-dialog modal-sm">' + '<div class="modal-content" style="margin-top: 24%;">' + '<div class="modal-header">'

                + '<h4 class="modal-title" style="margin-left:40%; color:white;"></h4>' + '</div>' + '<div class="modal-body" ng-transclude>  </div>' + '<button style="margin-left:35%;" type="button" ng-click="close();" class="btn btn-primary" data-dismiss="modal">Ok</button>' + '</div>' + '</div>' + '</div>',
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: true,
            link: function postLink(scope, element, attrs) {

                scope.$watch(attrs.visible, function(value) {

                    if (value == true) {
                        $(element).modal('show');
                    } else {
                        $(element).modal('hide');
                    }

                });

                $(element).on('shown.bs.modal', function() {
                    scope.$apply(function() {
                        scope.$parent[attrs.visible] = true;
                    });
                });

                $(element).on('hidden.bs.modal', function() {
                    scope.$apply(function() {
                        scope.$parent[attrs.visible] = false;
                    });
                });


            }
        };
    });

MainApp.directive('modalterm', function() {
    return {
        template: '<div class="modal fade" ng-click="closeTermsPopUp();">' +
            '<div class="modal-dialog">' +
            '<div class="modal-content termsConditions" ng-click="$event.stopPropagation();">' +
            '<div class="modal-header termsConditionsHeader">' +
            '<button type="button" ng-click="closeTermsPopUp();" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>' +
            '<h1 class="modal-title textalignclass">Terms and Conditions</h1>' +
            '</div>' +
            '<div class="modal-body termsConditionsbody" ng-transclude></div>' +
            '</div>' +
            '</div>' +
            '</div>',
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: true,
        backdrop: 'static',
        link: function postLink(scope, element, attrs) {
            scope.$watch(attrs.visible, function(value) {
                if (value == true)
                    $(element).modal('show');
                else
                    $(element).modal('hide');
            });

            $(element).on('shown.bs.modal', function() {
                scope.$apply(function() {
                    scope.$parent[attrs.visible] = true;
                });
            });

            $(element).on('hidden.bs.modal', function() {
                scope.$apply(function() {
                    scope.$parent[attrs.visible] = false;
                });
            });
        }
    };
});

