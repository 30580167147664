MainApp.controller("userActivationCntrl", ['$scope', 'HeaderVisibility', '$routeParams', 'generalServices', 'AppConstants', '$http', 'ipCookie', function($scope, HeaderVisibility, $routeParams, generalServices, AppConstants, $http, ipCookie) {

    $scope.token = $routeParams.param;
    $scope.data = { "type": "profile-activation", "token": $scope.token }
    $scope.showprogress = true;
    $scope.loadingclass = true;
    $scope.loadingmessage = "Checking the activation token validity..";
    setTimeout(function() {
        generalServices.userActivation($scope.data).then(function scb(resp) {
            $scope.showprogress = false;
            $scope.loadingclass = false;
            if (angular.equals(resp.data.code, 200)) {
                $scope.msg = resp.data.data.message;
                $scope.success = true;
            } else if (angular.equals(resp.data.code, 401)) {

                $scope.msg = resp.data.message;
                $scope.success = false;
            } else {
                $scope.msg = "Something went wrong";
                $scope.showFeedModal = true;
            }

        }, function ecb(err) {
            $scope.msg = "Something went wrong";
            $scope.showFeedModal = true;
            $scope.showprogress = false;
            $scope.loadingclass = false;
        });
    }, 2000);
    $scope.showResend = true;
    $scope.resendUserActivation = function() {
        $scope.showprogress = true;
        $scope.loadingclass = true;
        $scope.loadingmessage = "Resending the activation link...";
        $scope.Data = { "expiredToken": $scope.token }
        setTimeout(function() {
            generalServices.resendActivation($scope.Data).then(function scb(resp) {
                $scope.showprogress = false;
                $scope.loadingclass = false;
                if (angular.equals(resp.data.code, 200)) {
                    $scope.msg = resp.data.data.message;
                    $scope.showResend = false;
                } else if (angular.equals(resp.data.code, 401)) {
                    $scope.msg = resp.data.message;
                    $scope.showResend = false;
                } else {
                    $scope.msg = "Something went wrong";
                    $scope.showFeedModal = true;
                }

            }, function ecb(err) {
                $scope.msg = "Something went wrong";
                $scope.showFeedModal = true;
                $scope.showprogress = false;
                $scope.loadingclass = false;
            });
        }, 2000);

    }
    $scope.login = function() {
        var url = AppConstants.yappes_home_url + "/login";
        location.href = url;

    }

}]);
