MainApp.controller("yappesDefaultCntrl", ['$scope', 'connectServices', 'HeaderVisibility', '$location' ,'$interval',
    function($scope, connectServices, HeaderVisibility,$location,$interval) {

        $scope.showLoginHeader = true;
        $scope.showProfileHeader = false;
        $scope.videoUrl = "https://www.youtube.com/watch?v=xpFYlzPytQw";
        $scope.$emit('showLoginHeader', $scope.showLoginHeader);
        $scope.$emit('showProfileHeader', $scope.showProfileHeader);
        $scope.loadingmessage = "Loading ...";
        $scope.showModal = false;
        $scope.contactData = {
            "organizationName": "",
            "contactName": "",
            "contactEmail": "",
            "description": ""
        }
        $scope.apiMetrics = {
            "apiNumbers": 100,
            "transactionNumbers": 100,
            "userNumbers": 100
        };
        $scope.showUpArrow = true;

        $scope.getApiMetrics = function() {
            connectServices.getApiMetrics().then(function scb(resp) {
                $scope.apiMetrics.apiNumbers = resp.data.data.apiNumbers;
                $scope.apiMetrics.transactionNumbers = resp.data.data.transactionNumbers;
                $scope.apiMetrics.userNumbers = resp.data.data.userNumbers;
            }, function ecb(err) {});
        }

        $scope.sendEnquiry = function() {
            $scope.loadingmessage = "Enquiry Submission in progress...";
            $scope.showprogress = true;
            $scope.loadingclass = true;
            setTimeout(function() {
                connectServices.processEnquiry($scope.contactData).then(function scb(resp) {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    $scope.code = resp.data.code;
                    if (angular.equals(resp.data.code, 200)) {

                        $scope.msg = resp.data.data.message;
                        $scope.showModal = true;

                    } else if (angular.equals(resp.data.code, 400)) {
                        $scope.msg = resp.data.message;
                        $scope.showModal = true;
                    } else {
                        $scope.msg = resp.data.message;
                        $scope.showModal = true;
                    }

                }, function ecb(err) {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    $scope.msg = "Something went wrong !!";
                    $scope.showModal = true;
                });
            }, 2000);

        }
        // $scope.getApiMetrics();     
            
        $scope.navigateView = function(pathValue) {
                let path = "/"+pathValue;
                $location.path(path);
        }

        $scope.yappesIsForContents = [
            {
                "Developers":"are users who typically integrate APIs into their application or create APIs from scratch which are distributed internally or to the public at large."
            },
            {
                "Integrators":"are an individual user or a company that specializes in bringing complex subsystems as one with the help of APIs."
            },
            {
                "Enterprises":"are any company or organization which needs to implement an API strategy at the organization level to ensure better interoperability and external reach."
            }
        ];
        let count=0;

        $scope.headerYappesIsForContents = Object.keys($scope.yappesIsForContents[count])[0];
        $scope.bodyYappesIsForContents = $scope.yappesIsForContents[count][$scope.headerYappesIsForContents];
        

        $interval(function(){
            if(count>=3){
                count=0;
            } else {
                $scope.headerYappesIsForContents = Object.keys($scope.yappesIsForContents[count])[0];
                $scope.bodyYappesIsForContents = $scope.yappesIsForContents[count][$scope.headerYappesIsForContents];
                count++;
            }
        },4000);
        $scope.changeCard=function(){
            if(count>=3){
                count=0;
            } else {
                $scope.headerYappesIsForContents = Object.keys($scope.yappesIsForContents[count])[0];
                $scope.bodyYappesIsForContents = $scope.yappesIsForContents[count][$scope.headerYappesIsForContents];
                count++;
            }
        }
        $(window).scroll(function (event) {
            let scroll = $(window).scrollTop();
            let oldState = $scope.showUpArrow;
            if(scroll>100 || scroll==undefined){
                $scope.showUpArrow = false;
            }else{
                $scope.showUpArrow = true;
            }
            if($scope.showUpArrow !== oldState) {
                $scope.$apply();
            }
        });  
        $scope.redirect = function(arg){
            if(arg=="signin"){
            window.location = "/login";
            }else{
            window.location = "/signup"
            }

          }      
    }
]);
