MainApp.controller("public_profile_view_controller", ['$scope', 'HeaderVisibility', '$routeParams', 'generalServices', '$localStorage', '$timeout', 'Notification', 'AppConstants', 'localStorageService', 'ipCookie', 'Upload', 'SharedData', '$location', function($scope, HeaderVisibility, $routeParams, generalServices, $localStorage, $timeout, Notification, AppConstants, localStorageService, ipCookie, Upload, SharedData, $location) {
    if (SharedData.apiProviderData.currentUserName != '') {
        $scope.provideruserName = SharedData.apiProviderData.currentUserName;
    } else {
        $scope.provideruserName = ipCookie("previousApiProviderUserName");
    }
    $scope.showprogress = true;
    $scope.loadingclass = true;
    $scope.loadingmessage = "Loading ...";
    $timeout(function() {
        $scope.showprogress = false;
        $scope.loadingclass = false;
    }, 2000);

    $scope.showLoginHeader = HeaderVisibility.getLoginHeader();
    $scope.showProfileHeader = HeaderVisibility.getProfileHeader();
    $scope.$emit('showLoginHeader', $scope.showLoginHeader);
    $scope.$emit('showProfileHeader', $scope.showProfileHeader);
    if (SharedData.userData.emailId != '') {
        $scope.emailId = SharedData.userData.emailId;
        $scope.userName = SharedData.userData.userName;
        $scope.getNeedTutor = SharedData.userData.needTutor;
        $scope.getVideoSupport = SharedData.userData.needVideoSupport;
    } else {
        $scope.emailId = ipCookie("userEmail");
        $scope.userName = ipCookie("userName");
        $scope.getNeedTutor = ipCookie('needTutor');
        $scope.getVideoSupport = ipCookie('needVideoSupport');
    }
    $scope.setHeight = 300;
    $scope.activeProfileTab = 1;
    $scope.$emit('emailId', $scope.emailId);
    $scope.$emit('userName', $scope.userName);
    $scope.backNavigation = {
        "previous": "profileview",
        "filteredTag": "",
        "searchString": ""
    };
    $scope.getPublicProfile = function() {
        generalServices.getUserPublicProfile($scope.provideruserName).then(function scb(resp) {
            if (angular.equals(resp.data.code, 200)) {
                $scope.apiProviderProfile = resp.data.data.profileInformation;
                $scope.apiProviderProfile.memeberSince = new Date(resp.data.data.profileInformation.submittedDate);
                $scope.ProviderAPIs = resp.data.data.apiInformation.apisAsProvider;
                $scope.SubscribedAPIs = resp.data.data.apiInformation.apisAsSubscriber;
            } else if (angular.equals(resp.data.code, 401)) {

                $scope.msg = resp.data.message;
                $scope.showModal = true;
            } else {
                $scope.msg = resp.data.message;
                $scope.showModal = true;
            }
        }, function ecb(err) {
            $scope.msg = "Something went wrong !!";
            $scope.showModal = true;
        });
    }
    $scope.getPublicProfile();
    $scope.viewApi = function(apiId) {
        localStorageService.set("navigationState", $scope.backNavigation);
        var url = "consumer_view/" + apiId + "/" + $scope.emailId;
        $location.path(url);
    }
    $scope.IntroOptions = {

        showStepNumbers: false,
        showBullets: false,
        exitOnOverlayClick: true,
        exitOnEsc: true,
        nextLabel: '<strong>NEXT!</strong>',
        prevLabel: '<span style="color:green">Previous</span>',
        skipLabel: 'Exit',
        doneLabel: 'Thanks'
    };
    $scope.activeProviderIntroOptions = [{
        element: document.querySelector('#userProfile'),
        intro: "<div class='tour-header'>Profile Information</div><hr>" + "<div class='tour-step'>Basic and Social information of the User</div>",
        position: 'bottom'
    }, {
        element: document.querySelector('#publishedApis'),
        intro: "<div class='tour-header'>Published APIs</div><hr>" + "<div class='tour-step'>List of APIs published by this User</div>",
        position: 'bottom'
    }, {
        element: document.querySelector('#consumedApis'),
        intro: "<div class='tour-header'>Consumed APIs</div><hr>" + "<div class='tour-step'>List of APIs consumed by this User</div>",
        position: 'bottom'
    }, ]
    $scope.IntroOptions.steps = $scope.activeProviderIntroOptions;
    $timeout(function() {
        if ($scope.getNeedTutor) {
            $scope.CallMe();
        }
    }, 1000);
    $scope.tabChange = function(activeElem) {
        angular.element("#publishedApis").removeClass("active");
        angular.element("#tab_publishedApis").removeClass("active");
        angular.element("#consumedApis").removeClass("active");
        angular.element("#tab_consumedApis").removeClass("active");

        angular.element("#" + activeElem).addClass("active");
        angular.element("#tab_" + activeElem).addClass("active");
    }
    $scope.ChangeEvent = function(targetElement, scope) {
        if (targetElement.id == 'publishedApis') {
            $scope.activeProfileTab = 1;
            $scope.tabChange(targetElement.id);

        } else if (targetElement.id == 'consumedApis') {
            $scope.activeProfileTab = 2;
            $scope.tabChange(targetElement.id);

        }
    }
}]);
