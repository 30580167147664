MainApp.controller("apiOauth1CallbackController", ['$scope', 'generalServices', 'HeaderVisibility', 'AppConstants', 'ipCookie', 'SharedData', 'connectServices', '$window', '$location', 'localStorageService', function($scope, generalServices, HeaderVisibility, AppConstants, ipCookie, SharedData, connectServices, $window, $location, localStorageService) {
    $scope.showprogress = true;
    $scope.loadingclass = true;
    $scope.loadingmessage = "retrieving Access Token ...";
    $scope.oauth_token="";
    //access
    let oauth1Schema={
        "oauth_consumer_key":"",
        "oauth_consumer_secret":"",
        "oauth_signature_method":"",
        "token":"",
        "tokenSecret":"",
        "signatureMethod":"",
        "oauth_timestamp":"",
        "oauth_nonce":"",
        "oauth_version":"",
        "realm":"",
        "oauth_callback":"",
        "oauth_signature":"",
        "oauth_verifier":"",
        "oauth_token":"",
        "oauth_token_secret":""
    };
    $scope.getParameterByName = function(urlString) {
        let accessTokenDetails = {
            oauth_token:"",
            oauth_token_secret:""
        }

        let toProcess = urlString.split("&");
        for(let strCount=0; strCount<toProcess.length; strCount++){
            if(toProcess[strCount].indexOf("oauth_token=")!==-1){
                accessTokenDetails.oauth_token=toProcess[strCount].split("=")[1];
            }
            if(toProcess[strCount].indexOf("oauth_token_secret=")!==-1){
                accessTokenDetails.oauth_token_secret=toProcess[strCount].split("=")[1];
            }
        }
        return accessTokenDetails;
    }

    var urllocation = $location.absUrl();
    var searchObject = $location.search();
    
    if (localStorageService.get("previewDetails") != null || !angular.isUndefined(localStorageService.get("previewDetails"))) {
        $scope.authenticationCookie = JSON.parse(localStorageService.get('previewDetails'));
    } else if (ipCookie('previewDetails') != null) {
        $scope.authenticationCookie = JSON.parse(ipCookie('previewDetails'));
    } else {
        $scope.authenticationCookie = "";
    }

    $scope.apiId = $scope.authenticationCookie['id'];
    $scope.emailId = $scope.authenticationCookie['emailId'];
    connectServices.addConsumerOauth1AccessTokenAuthentication(searchObject).then(function scb(resp){
        $scope.accessTokenResponse = resp.data;
        if($scope.accessTokenResponse.hasOwnProperty("error") && $scope.accessTokenResponse.error=="unauthorized"){
            if ($scope.authenticationCookie['preview'] == 'provider') {
                ipCookie("redirection", true);
                $window.sessionStorage.setItem('redirection', 'authorized');
                var url = "api_Preview/" + $scope.apiId;
                $location.path(url);

            } else if ($scope.authenticationCookie['preview'] == 'consumer') {
                ipCookie("redirection_consumer", true);
                $window.sessionStorage.setItem('redirection_consumer', 'consumerauthorized');
                var url = "consumer_view/" + $scope.apiId + "/" + $scope.emailId;
                $location.path(url);
            }
            
            
        } else {
            let processedTokenDetails = $scope.getParameterByName($scope.accessTokenResponse);
            $scope.accessTokenDetails = angular.copy($scope.authenticationCookie['authData']);
            $scope.accessTokenDetails["parameters"]["oauth_token"] = processedTokenDetails["oauth_token"];
            $scope.accessTokenDetails["parameters"]["oauth_token_secret"] = processedTokenDetails["oauth_token_secret"];
            connectServices.addConsumerAuthentication($scope.apiId, $scope.accessTokenDetails).then(function scb(resp) {
                if (angular.equals(resp.data.code, 200)) {
                    if ($scope.authenticationCookie['preview'] == 'provider') {
                        ipCookie("redirection", true);
                        $window.sessionStorage.setItem('redirection', 'authorized');
                        var url = "api_Preview/" + $scope.apiId;
                        $location.path(url);

                    } else if ($scope.authenticationCookie['preview'] == 'consumer') {
                        ipCookie("redirection_consumer", true);
                        $window.sessionStorage.setItem('redirection_consumer', 'consumerauthorized');
                        var url = "consumer_view/" + $scope.apiId + "/" + $scope.emailId;
                        $location.path(url);
                    }

                } else if (angular.equals(resp.data.code, 400)) {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    $scope.msg = resp.data.message;
                    $scope.showModal = true;
                } else if (angular.equals(resp.data.code, 401)) {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    $scope.msg = resp.data.message;
                    $scope.showModal = true;
                } else {
                    $scope.showprogress = false;
                    $scope.loadingclass = false;
                    $scope.msg = "Something happened !!! Try again ...";
                    $scope.showModal = true;
                }
            }, function ecb(err) {
                $scope.msg = err.data.message;
                $scope.showModal = true;
            });            
        }
    }).then(function ecb(err){
        console.log(err);
    });
}]);